import { HStack, Heading, Icon } from "@chakra-ui/react";
import Layout from "../components/Layout";
import { FaClipboardCheck } from "react-icons/fa"
import { useCallback, useEffect, useState } from "react";

export default function PaymentSuccess({innerWidth}) {
    const success = {
        ro: 'Plata a fost efectuată cu success',
        en: 'Payment done successfully'
    }

    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    return (
        <Layout title={lang==='RO' ? "Plată Efectuată" : 'Payment Successful'}>
            <HStack pt="40vh" justifyContent='center' display={innerWidth>=675 ? 'flex' : 'none'}>
                <Heading fontSize={['5xl',, '6xl']} textAlign="center">{success[lang.toLowerCase()]}</Heading>
                <Icon as={FaClipboardCheck} fontSize='5vh' color="green" />
                <Heading fontSize={['5xl',, '6xl']} textAlign="center">!</Heading>
            </HStack>
            <Heading display={innerWidth<675 ? 'block' : 'none'} fontSize={['5xl',, '6xl']} textAlign="center" pt="40vh">{success[lang.toLowerCase()]} <Icon as={FaClipboardCheck} color="green" />!</Heading>
        </Layout>
    )
}