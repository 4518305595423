import { Box, Text } from "@chakra-ui/react";


export default function StarRating({rating}) {
    const starArr = [1, 2, 3, 4, 5]
    return (
        <Box>
            {
                starArr.map((star, index) => {

                    return (
                        <Text as="span" fontSize={['lg',, '2xl']} key={index} className={index+1<=rating ? 'starOn' : 'starOff'}>&#9733;</Text>
                    )
                })
            }
        </Box>
    )
}