import { Heading, Input, InputGroup, InputLeftElement, VStack, Icon, Text, Button } from "@chakra-ui/react";
import Layout from "../components/Layout";
import { MdEmail } from "react-icons/md";
import { useCallback, useEffect, useState } from "react";
import { axiosInstance } from "../config";
import Loading from "../components/Loading"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { isAuth } from "../utils/authHelpers";

export default function ForgetPassword() {

    useEffect(()=>{
        if(isAuth()) window.location.href='/cont'
    })

    const [email, SetEmail] = useState("")
    const [loading, setLoading] = useState(false)

    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            await axiosInstance.post('/users/forgetPassword', { email: email, lang })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.message[lang.toLowerCase()])
            })
            
        } catch (err) {
            setLoading(false)
            toast.error(err.response.data.error[lang.toLowerCase()])
        }
    }
    return (
        <Layout title={lang==='RO' ? "Resetare Parolă" : 'Reset Password'}>
            <ToastContainer pauseOnHover theme="colored" style={{
                marginTop: '150px'
            }} />
            {
                loading ? (
                    <Loading />
                ) : (
                    <VStack mt={40} width="fit-content" ml="auto" mr="auto" p={[6, , 16]} borderRadius={8} className="registration">
                        <Heading fontSize={['5xl', , '6xl']} textAlign='center'>{lang==='RO' ? 'Ți-ai uitat parola' : 'Forgot password'}?</Heading>
                        <Text fontSize={['lg', , '2xl']}>{lang==='RO' ? 'Vă rugăm, introduceți adresa de e-mail mai jos. Veți primi un link pentru resetarea parolei' : 'Please write your email adress and an email with the reset password link will be sent'}.</Text>
                        <InputGroup>
                            <InputLeftElement children={<Icon color="black.dark" mt={2} fontSize='2.5vh' as={MdEmail} />} />
                            <Input fontSize={['lg', , '2xl']} value={email} onChange={(e) => SetEmail(e.target.value)} type="email" name="email" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                        </InputGroup>
                        <Button fontSize={['lg', , '2xl']} onClick={handleSubmit} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang==='RO' ? 'Trimite' : 'Send'}</Button>
                    </VStack>
                )
            }
        </Layout>
    )
}