const arr = [0, 0, 0, 0, 0]
const roomsCombinations = []
const maxPers = [1, 1, 2, 2, 4]
let nr = 0

function pushCombination(pers) {
    let ok = 0
    for (let i = 1; i <= 4; i++)
        if (arr[i] !== 0) ok++;
    if (ok > 1) {
        let aux = 0
        for (let i = 1; i <= 4; i++)
            aux += arr[i] * maxPers[i]
        for (let i = 1; i <= 4; i++)
            if (arr[i] > 1 || ok > 2) {
                if (aux - maxPers[i] >= pers) {
                    ok = 0
                    break
                }
            }
    }
    if (ok !== 0) {
        roomsCombinations[nr] = []
        for (let i = 1; i <= 4; i++)
            roomsCombinations[nr][i] = arr[i]
    }
}

function verif(k, pers) {
    let aux = 0
    let ok = 0
    for (let i = 1; i < k; i++) {
        if (arr[i] !== 0) ok++
        aux += maxPers[i] * arr[i]
    }
    if (arr[k] * maxPers[k] >= pers && aux > 0) return false
    if (aux + (arr[k] - 1) * maxPers[k] >= pers) return false
    if (k === 4 && aux + arr[k] * maxPers[k] < pers) return false
    if ((ok >= 1 && arr[k] === 0)) return true
    if ((aux > pers) || (aux === pers && arr[k] > 0)) return false
    return true
}

function bt(pers) {
    let k = 1
    arr[k] = -1
    while (k > 0) {
        while (arr[k] < Math.ceil(pers / maxPers[k])) {
            arr[k]++
            if (verif(k, pers)) {
                if (k === 4) {
                    pushCombination(pers)
                    nr++;
                }
                else {
                    k++
                    arr[k] = -1
                }
            }
        }
        k--
    }
}

export default function getNrOfRooms(pers) {
    for (let i = 0; i <= 4; i++)
        arr[i] = 0
    while (roomsCombinations.length) roomsCombinations.pop()
    nr = 0
    bt(pers)
    return roomsCombinations
}