import { Alert, AlertDescription, AlertIcon, AlertTitle, Text, Stack, StackDivider, Box, VStack, Input, Textarea, Button, Heading, useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, InputGroup, InputLeftElement, HStack, Icon } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import { axiosInstance } from "../config";
import Loading from "./Loading"
import StarRating from "./StarRating";
import { ChevronRightIcon, ChevronLeftIcon, EmailIcon, CheckIcon } from "@chakra-ui/icons"
import { motion } from "framer-motion"
import { BsFillPersonFill } from "react-icons/bs";
import { FaRobot } from "react-icons/fa"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const op1 = Math.floor(Math.random() * 20 + 1)
const op2 = Math.floor(Math.random() * 10 + 1)

export default function Review({ innerWidth }) {

    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        if (window.localStorage.lang === 'RO')
            setFormData({ ...formData, name: 'Anonim', review: '', stars: 0, antiBot: '' })
        else setFormData({ ...formData, name: 'Anonymous', review: '', stars: 0, antiBot: '' })
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const seeReviews = useDisclosure()
    const postReviews = useDisclosure()
    const [loading, setLoading] = useState(true)
    const [err, setErr] = useState()
    const [reviews, setReviews] = useState([])
    const [medRating, setMedRating] = useState(0)
    const [state, setState] = useState("unsent")
    const [hovered, setHovered] = useState(false)

    const [formData, setFormData] = useState({
        name: 'Anonim',
        review: '',
        stars: 0,
        antiBot: ''
    })

    const errorBot = {
        ro: 'Rezultatul operatiei este gresit',
        en: 'The sum result is wrong'
    }
    const error = {
        ro: 'Toate campurile sunt obligatorii',
        en: 'All fields are required'
    }
    const success = {
        ro: 'Recenzia a fost adaugata',
        en: 'The review was posted'
    }

    const sortByRating = (arr) => {
        return [...arr].filter((e) => { return e.stars >= 4 }).sort((a, b) => b.stars - a.stars)
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const data = (await axiosInstance.get('/reviews')).data
                setReviews(data.reviews)
                setMedRating(data.medRating)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setErr(true)
            }
        }
        getData()
    }, [])

    const [errorState, setErrorState] = useState({
        stars: false,
        review: false,
    })

    const nameErr = {
        ro: 'Numele nu poate fi gol',
        en: 'Name cannot be empty'
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!formData.name) {
            toast.error(nameErr[lang.toLowerCase()])
        } else {
            if (formData.stars === 0) setErrorState({ ...errorState, stars: true })
            if (!formData.review) setErrorState({ ...errorState, review: true })
            if (!formData.review || !formData.stars) {
                toast.error(error[lang.toLowerCase()])
            }
            else if (op1 + op2 !== Number(formData.antiBot))
                toast.error(errorBot[lang.toLowerCase()])
            else {
                await axiosInstance.post('/reviews/addReview', { name: formData.name, review: formData.review, stars: formData.stars, lang: lang.toLowerCase() })
                toast.success(success[lang.toLowerCase()])
                setState('sent')
            }
        }
    }

    const handleClose = () => {
        postReviews.onClose()
        if (lang === 'RO')
            setFormData({ ...formData, name: 'Anonim', review: '', stars: 0, antiBot: '' })
        else setFormData({ ...formData, name: 'Anonymous', review: '', stars: 0, antiBot: '' })
    }

    const handleRating = (stars) => {
        setFormData({ ...formData, stars: stars })
    }

    const arrowAnim = {
        open: {
            x: [-10, 0],
            opacity: 1
        },
        close: {
            opacity: 0
        }
    }

    return (
        <Box textAlign="center" p={8} width="100%">
            {
                loading ? (
                    <Loading />
                ) : (
                    err ? (
                        <Alert status="error" fontSize={['2xl', , '3xl']} width='fit-content' ml='auto' mr='auto'>
                            <AlertIcon />
                            <AlertTitle>{lang === 'RO' ? 'A apărut o eroare!' : 'Something went wrong!'}</AlertTitle>
                            <AlertDescription>{lang === 'RO' ? 'Vă rugăm să reîncărcați pagina sau să reveniți mai târziu' : 'Please try again later'}</AlertDescription>
                        </Alert>
                    ) : (
                        <Box>
                            <Heading fontSize={['5xl', , '6xl']}>{lang === 'RO' ? 'Recenzii' : 'Reviews'}</Heading>
                            <Stack justifyContent='space-around' direction={innerWidth >= 900 ? 'row' : 'column'}>
                                <VStack width={innerWidth >= 900 ? '50%' : '100%'}>
                                    <Box>
                                        {
                                            [1, 2, 3, 4, 5].map((star, index) => {

                                                return (
                                                    <Text as="span" fontSize={['5xl', , '7xl']} key={index} className={index + 1 <= medRating ? 'starOn' : 'starOff'}>&#9733;</Text>
                                                )
                                            })
                                        }
                                    </Box>
                                    <Stack direction={['column', , 'row']}>
                                        <Button width='100%' onClick={seeReviews.onOpen} className="btn-group" p={6} fontSize={['lg', , '2xl']} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Vezi Recenzii' : 'See Reviews'}</Button>
                                        <Button width='100%' onClick={postReviews.onOpen} className="btn-group" p={6} fontSize={['lg', , '2xl']} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Adaugă Recenzii' : 'Add Review'}</Button>
                                    </Stack>
                                </VStack>
                                <Box width={innerWidth >= 900 ? '50%' : '100%'} pt={8} pb={8} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}>
                                    <Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false} nextArrow={<motion.div animate={hovered ? "open" : "close"} variants={arrowAnim}><Icon as={ChevronRightIcon} fontSize="8vh" color="accents.raw-sienna" /></motion.div>} prevArrow={<motion.div animate={hovered ? "open" : "close"} variants={arrowAnim}><Icon as={ChevronLeftIcon} fontSize="8vh" color="accents.raw-sienna" /></motion.div>}>
                                        {
                                            sortByRating(reviews).map(review => {
                                                const date = String(review.createdAt.split('-')[2].split('T')[0]) + '/' + String(review.createdAt.split('-')[1]) + '/' + String(review.createdAt.split('-')[0])
                                                return (
                                                    <VStack key={review._id}>
                                                        <Heading fontSize={['2xl', , '4xl']}>
                                                            {
                                                                review.name === 'Anonim' || review.name === 'Anonymous' ?
                                                                    lang === 'RO' ? 'Anonim' : 'Anonymous'
                                                                    : review.name
                                                            } <Text as='span' fontSize={['lg', , '2xl']} color="grey.odt">- {date}</Text>
                                                        </Heading>
                                                        <StarRating rating={review.stars} />
                                                        <Text width='50%' textAlign='center' fontSize={['xl', , '3xl']}>{review[lang.toLowerCase()].substring(0, 125)}{review[lang.toLowerCase()].length <= 125 ? '' : '...'}</Text>
                                                        <Text fontSize={['md', , 'lg']} display={review.translatedFrom === 'en' && lang === 'RO' ? 'block' : 'none'}>Tradus din engleză</Text>
                                                        <Text fontSize={['md', , 'lg']} display={review.translatedFrom === 'ro' && lang === 'EN' ? 'block' : 'none'}>Translated from Romanian</Text>
                                                    </VStack>
                                                )
                                            })
                                        }
                                    </Slide>
                                </Box>
                            </Stack>
                            <Modal isOpen={seeReviews.isOpen} onClose={seeReviews.onClose} size="4xl">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalCloseButton onClick={seeReviews.onClose} />
                                    <ModalBody p={16}>
                                        <VStack divider={<StackDivider borderColor="accents.manhattan" />} alignItems="flex-start">
                                            {
                                                reviews.map(review => {
                                                    const date = String(review.createdAt.split('-')[2].split('T')[0]) + '/' + String(review.createdAt.split('-')[1]) + '/' + String(review.createdAt.split('-')[0])
                                                    return (
                                                        <Box key={review._id}>
                                                            <Heading fontSize={['2xl', , '4xl']}>
                                                                {
                                                                    review.name === 'Anonim' || review.name === 'Anonymous' ?
                                                                        lang === 'RO' ? 'Anonim' : 'Anonymous'
                                                                        : review.name
                                                                } <Text as='span' fontSize={['lg', , '2xl']} color="grey.odt">- {date}</Text>
                                                            </Heading>
                                                            <StarRating rating={review.stars} />
                                                            <Text fontSize={['xl', , '3xl']}>{review[lang.toLowerCase()]}</Text>
                                                            <Text fontSize={['md', , 'lg']} display={review.translatedFrom === 'en' && lang === 'RO' ? 'block' : 'none'}>Tradus din engleză</Text>
                                                            <Text fontSize={['md', , 'lg']} display={review.translatedFrom === 'ro' && lang === 'EN' ? 'block' : 'none'}>Translated from Romanian</Text>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </VStack>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                            <Modal isOpen={postReviews.isOpen} onClose={postReviews.onClose} size="4xl">
                                <ModalOverlay />
                                <ModalContent>
                                    <ToastContainer pauseOnHover theme="colored" style={{
                                        marginTop: '150px'
                                    }} />
                                    <ModalCloseButton onClick={handleClose} />
                                    <ModalBody p={16}>
                                        <Heading mb={2} textAlign="center">{lang === 'RO' ? 'Lasă o recenzie' : 'Add a review'}</Heading>
                                        <form onSubmit={handleSubmit}>
                                            <VStack fontSize={['lg', , '2xl']}>
                                                <Box w="100%">
                                                    <VStack>
                                                        <Text pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Nume: (Opțional)' : 'Name: (Optional)'}</Text>
                                                        <InputGroup>
                                                            <InputLeftElement pt={2}><BsFillPersonFill fontSize="3vh" /></InputLeftElement>
                                                            <Input fontSize={['lg', , '2xl']} value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="text" size='lg' focusBorderColor="black.jon" />
                                                        </InputGroup>
                                                    </VStack>

                                                    <VStack>
                                                        <Text color={errorState.stars && 'red'} pt={4} alignSelf="flex-start">Rating:</Text>
                                                        <HStack>
                                                            <Text cursor='pointer' as="span" fontSize={['3xl', , '5xl']} className={1 <= formData.stars ? 'starOn' : 'starOff'} onClick={() => handleRating(1)}>&#9733;</Text>
                                                            <Text cursor='pointer' as="span" fontSize={['3xl', , '5xl']} className={2 <= formData.stars ? 'starOn' : 'starOff'} onClick={() => handleRating(2)}>&#9733;</Text>
                                                            <Text cursor='pointer' as="span" fontSize={['3xl', , '5xl']} className={3 <= formData.stars ? 'starOn' : 'starOff'} onClick={() => handleRating(3)}>&#9733;</Text>
                                                            <Text cursor='pointer' as="span" fontSize={['3xl', , '5xl']} className={4 <= formData.stars ? 'starOn' : 'starOff'} onClick={() => handleRating(4)}>&#9733;</Text>
                                                            <Text cursor='pointer' as="span" fontSize={['3xl', , '5xl']} className={5 <= formData.stars ? 'starOn' : 'starOff'} onClick={() => handleRating(5)}>&#9733;</Text>
                                                        </HStack>
                                                    </VStack>

                                                    <Text color={errorState.review && 'red'} pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Recenzie' : 'Review'}:</Text>
                                                    <Textarea fontSize={['lg', , '2xl']} isInvalid={errorState.review} value={formData.review} onChange={(e) => setFormData({ ...formData, review: e.target.value })} borderStyle="solid" fontWeight="bold" borderWidth={2} borderColor="accents.manhattan" required size='lg' maxH="150px" focusBorderColor="black.jon" />

                                                    <Text pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Rezolva operatia' : 'Solve'}: {op1 + " + " + op2}</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><FaRobot fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} value={formData.antiBot} onChange={(e) => setFormData({ ...formData, antiBot: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="email" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>

                                                    <Text fontSize={['md', , 'xl']} display={lang === 'RO' ? 'block' : 'none'} pt={4} alignSelf="flex-start" color="grey.obs">*Notă: Vă rugăm să folosiți diacritice în recenzia dvs.</Text>
                                                </Box>
                                            </VStack>
                                            <Button isDisabled={state === 'sent' ? true : false} onClick={handleSubmit} className="btn-group" bg="black.dark" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2} type="submit" rightIcon={state === 'unsent' ? <EmailIcon /> : <CheckIcon />} fontSize={["lg", , "2xl"]} mt={4}>
                                                {state === 'unsent' ?
                                                    lang === 'RO' ? 'Trimite' : 'Send'
                                                    : lang === 'RO' ? 'Trimis' : 'Sent'
                                                }
                                            </Button>
                                        </form>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </Box>
                    )
                )
            }
        </Box>
    )
}