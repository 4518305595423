import { Box, Grid, HStack, Image, Stack, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import Carousel from "../components/Carousel";
import Layout from "../components/Layout";
import RoomCard from "../components/RoomCard";


export default function Camere({ innerWidth, roomTypes, roomsImages }) {
    const firstImg = [roomsImages.single[0], roomsImages.double[0], roomsImages['king-size'][0], roomsImages.apartment[0]]
    const [y, setY] = useState(window.scrollY)
    const handleNavigation = useCallback((e) => {
        const window = e.currentTarget;
        setY(window.scrollY);
    }, [y])

    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    return (
        <Layout title={lang === 'RO' ? "Camere" : 'Rooms'}>
            <Box position='fixed' zIndex={-2} left={0} top={['12vh', , 0]} width="100%" height="90vh">
                <Image transform={y > 80 ? "scale(1.15)" : ''} transitionDuration="0.5s" src="/images/exterior/15.jpg" width="100%" height={["65vh", , "100vh"]} />
            </Box>
            <Box pt={[4, , 8]} pb={[4, , 8]} width="100%" mt={innerWidth <= 1220 ? '65vh' : '75vh'} bgColor="white.cultured" >
                <Box mt={12} width="100%" height="100%" display={innerWidth <= 1220 ? 'block' : 'none'}>
                    <Carousel roomTypes={roomTypes} elements={firstImg} lang={lang} />
                </Box>
                <HStack mt={12} width="100%" display={innerWidth >= 1221 ? 'flex' : 'none'} justifyContent="space-evenly" alignItems='stretch'>
                    {
                        firstImg.map((img, index) => (
                            <RoomCard key={index} innerWidth={innerWidth} type={roomTypes[index].type} area={roomTypes[index].area} img={img} maxCount={roomTypes[index].maxCount} desc={roomTypes[index].desc[lang.toLowerCase()]} price={roomTypes[index].price} lang={lang} />
                        ))
                    }
                </HStack>
                
            </Box>
        </Layout>
    )
}