import { Box, Icon, Image, Text, Stack, VStack, Link } from "@chakra-ui/react";
import Layout from "../components/Layout";
import { useCallback, useEffect, useState } from "react";
import Contact from "../components/Contact";
import Review from "../components/Review";
import { CheckIcon } from "@chakra-ui/icons"
import { motion } from "framer-motion"

export default function Home({ innerWidth }) {
    const [y, setY] = useState(window.scrollY)
    const [lang, setLang] = useState(window.localStorage.lang)

    const handleNavigation = useCallback((e) => {
        const window = e.currentTarget;
        setY(window.scrollY);
    }, [y])

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const textAnim = {
        offScreen: {
            y: 100,
            x: 100,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    }

    const text2Anim = {
        offScreen: {
            y: -100,
            x: 100,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    }

    const attractions = [
        {
            name: lang === 'RO' ? 'Ansamblul Curtea Domnească' : 'Royal Court Museum',
            id: 1,
            distance: '240 m',
            link: 'https://goo.gl/maps/NfGAoobxiNfYJp716',
        },
        {
            name: lang === 'RO' ? 'Muzeul de Istorie' : 'History Museum',
            id: 2,
            distance: '600 m',
            link: 'https://goo.gl/maps/mq2ia97ongE1k5xDA',
        },
        {
            name: lang === 'RO' ? 'Muzeul de Artă Eneolitică' : 'Neolithic Art Museum',
            id: 3,
            distance: '300 m',
            link: 'https://goo.gl/maps/Vs1Mr9mgSSSz1HbQA',
        },
        {
            name: lang === 'RO' ? 'Muzeul de Artă' : 'Art Museum',
            id: 4,
            distance: '300 m',
            link: 'https://goo.gl/maps/3nd7dQKJ3SakwTjH8',
        },
        {
            name: lang === 'RO' ? 'Muzeul de Etnografie' : 'Ethnography Museum',
            id: 5,
            distance: '300 m',
            link: 'https://goo.gl/maps/mdWznHZL3K8WyA5N8',
        },
        {
            name: lang === 'RO' ? 'Teatrul Tineretului' : "Piatra Neamţ Youth's Theater",
            id: 6,
            distance: '250 m',
            link: 'https://goo.gl/maps/wogU7QA9bdm49AgTA',
        },
        {
            name: lang === 'RO' ? 'Muzeul de Ştiințe Naturale' : 'Natural Science Museum',
            id: 7,
            distance: '350 m',
            link: 'https://goo.gl/maps/wc88CK8BryVGNcTk7',
        },
        {
            name: lang === 'RO' ? 'Complexul de Agrement Ştrand' : 'Recreation Park',
            id: 8,
            distance: '2.5 km',
            link: 'https://goo.gl/maps/cgjycb14RwhjHnUDA',
        },
        {
            name: lang === 'RO' ? 'Telegondola' : 'Gondola',
            id: 9,
            distance: '1.1 km',
            link: 'https://goo.gl/maps/bt4diBSQF4itiAfcA',
        },
        {
            name: lang === 'RO' ? 'La Caiace' : 'Kayaks',
            id: 10,
            distance: '4.2 km',
            link: 'https://goo.gl/maps/v5J4i5hH83wptKxW8',
        },
        {
            name: lang === 'RO' ? 'Cheile Bicazului' : 'Bicaz Canyon',
            id: 11,
            distance: '54.7 km',
            link: 'https://goo.gl/maps/yGNUY8eGZTDZT6rD8',
        },
        {
            name: lang === 'RO' ? 'Lacul Roșu' : 'Red Lake',
            id: 12,
            distance: '60 km',
            link: 'https://goo.gl/maps/sccXXjrcLWrcz2L87',
        },
        {
            name: lang === 'RO' ? 'Cetatea Neamțului' : 'Neamţ Citadel',
            id: 13,
            distance: '45 km',
            link: 'https://goo.gl/maps/ipcVtLZ6Z7DGXXQH7',
        },
        {
            name: lang === 'RO' ? 'Casa Memorială "Ion Creangă"' : 'Ion Creanga Memorial House',
            id: 14,
            distance: '42 km',
            link: 'https://goo.gl/maps/5Wzud3PfpKpoMZJU7',
        }
    ]

    const attractionAnim = {
        offScreen: {
            x: 200,
            opacity: 0
        },
        onScreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.5,
                duration: 1,
            }
        }
    }

    return (
        <Layout title='Grand Hotel Ceahlău'>
            <Box position='fixed' zIndex={-2} left={0} top={innerWidth < 950 ? "14vh" : "0"} width="100%" height="80vh">
                <Image transform={y > 80 ? "scale(1.15)" : ''} transitionDuration="0.5s" src="/images/exterior/36.jpg" width="100%" height={innerWidth < 950 ? '50vh' : '100vh'} />
            </Box>
            <Box overflow="hidden" mt={innerWidth < 950 ? '55vh' : '80vh'} bg="white.cultured" width="100%" pt={16} pb={16} pl={[10, , 52]} pr={[10, , 52]} bgImage="url('/images/bg.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
                <motion.div initial={textAnim.offScreen} whileInView={textAnim.onScreen} viewport={{ once: true }}>
                    <Text textAlign={["left", , "justify"]} fontSize={['xl', , '3xl']} display={lang === 'RO' ? 'block' : 'none'}>
                        &emsp;Fiind o marcă renumită în peisajul hotelier al Judeţului Neamţ si al oraşului Piatra Neamţ, Grand Hotel Ceahlău a dat întotdeauna dovadă de o activitate de calitate în toţi anii de funcţionare.
                        Notorietatea locală a mărcii şi calitatea serviciilor au determinat recunoaşterea hotelului. De asemenea, dotările materiale şi facilităţile oferă diverse posibilităţi de organizare pentru diferite
                        activităţi în cadrul hotelului (întruniri, conferinţe, evenimente, etc.) dar şi pentru petrecerea unei perioade relaxante într-un cadru plăcut în care pot fi realizate diverse acţiuni turistice.
                    </Text>
                    <Text textAlign={["left", , "justify"]} fontSize={['xl', , '3xl']} display={lang === 'EN' ? 'block' : 'none'}>
                        &emsp;Being one of the most renowned hotels in Piatra Neamţ and in Neamţ County, Grand Hotel Ceahlău has always proved its high quality activity since its first days.
                        The local notoriety of the brand and the services quality help our hotel to gain recognition. Also, the facilities offer diverse possibilities to organize different activities, such as meetings, or events,
                        while still being able to ensure a relexing vacation where you can sightsee the beautiful city.
                    </Text>
                </motion.div>
            </Box>
            <Box position='sticky' zIndex={-1} left={0} top={innerWidth < 950 ? "12vh" : 0} width="100%" height="80vh">
                <Image transitionDuration="0.4s" src="/images/exterior/10.jpg" width="100%" height={innerWidth < 950 ? '65vh' : '100vh'} />
            </Box>
            <Box overflow="hidden" mt={innerWidth < 950 ? "-15vh" : '10vh'} bg="white.cultured" bgImage="url('/images/bg.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="cover" width="100%" pt={16} pb={16} pl={[10, , 52]} pr={[10, , 52]}>
                <motion.div initial={text2Anim.offScreen} whileInView={text2Anim.onScreen} viewport={{ once: true }}>
                    <Text textAlign={["left", , "justify"]} fontSize={['xl', , '3xl']} display={lang === 'RO' ? 'block' : 'none'}>
                        &emsp;Grand Hotel Ceahlău este situat ideal pentru un turism de calitate, având în vedere faptul că acesta se afla la distanţe foarte convenabile faţă de principalele atracţii turistice ale judeţului Neamţ.
                    </Text>
                    <Text textAlign={["left", , "justify"]} fontSize={['xl', , '3xl']} display={lang === 'EN' ? 'block' : 'none'}>
                        &emsp;Grand Hotel Ceahlău is perfectly placed for the best sightseeing experience, being close to the most important landmarks of the Neamţ County.
                    </Text>
                    <Text textAlign={["left", , "justify"]} fontSize={['xl', , '3xl']} display={lang === 'RO' ? 'block' : 'none'}>
                        &emsp;Atracţii turistice din judeţul Neamţ si distanţa acestora fata de hotelul nostru:
                    </Text>
                    <Text textAlign={["left", , "justify"]} fontSize={['xl', , '3xl']} display={lang === 'EN' ? 'block' : 'none'}>
                        &emsp;Landmarks from Neamţ County and the distance from our hotel:
                    </Text>
                </motion.div>
                <VStack pl={[0, , 16]} pt={4} fontSize={['xl', , '3xl']} justifyContent="flex-start" alignItems="flex-start">
                    {
                        attractions.map(attraction => (
                            <motion.div initial={attractionAnim.offScreen} whileInView={attractionAnim.onScreen} viewport={{ once: true }}>
                                <Stack key={attraction.id} direction={['column', , 'row']} justifyContent="flex-start">
                                    <Link href={attraction.link} isExternal><Text overflowWrap="break-word" fontSize={['xl', , '3xl']} _hover={{ border: '2px solid', borderColor: 'accents.manhattan', borderRadius: 5 }}><Icon as={CheckIcon} />{attraction.name}</Text></Link>
                                    <Text>- {attraction.distance}</Text>
                                </Stack>
                            </motion.div>
                        ))
                    }
                </VStack>
            </Box>
            <Box position='sticky' zIndex={-1} left={0} top={innerWidth < 950 ? "12vh" : 0} width="100%" height="90vh">
                <Image transitionDuration="0.4s" src="/images/exterior/16.jpg" width="100%" height={innerWidth < 950 ? '65vh' : '100vh'} />
            </Box>
            <Box mt={innerWidth < 950 ? "-25vh" : 0} bgColor="white.cultured" bgImage="url('/images/bg.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
                <Contact />
            </Box>
            <Box position='sticky' zIndex={-1} left={0} top={innerWidth < 950 ? "12vh" : 0} width="100%" height="90vh">
                <Image transitionDuration="0.4s" src="/images/exterior/23.jpg" width="100%" height={innerWidth < 950 ? '65vh' : '100vh'} />
            </Box>
            <Box mt={innerWidth < 950 ? "-25vh" : 0} bgColor="white.cultured" bgImage="url('/images/bg.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
                <Review innerWidth={innerWidth} />
            </Box>
        </Layout>
    )
}