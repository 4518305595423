import { Box, VStack, Input, Textarea, Button, Icon, Heading, useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, InputGroup, InputLeftElement, Text } from "@chakra-ui/react";
import { EmailIcon, CheckIcon } from "@chakra-ui/icons";
import { useCallback, useEffect, useState } from "react";
import { MdLocalPhone } from "react-icons/md"
import { HiMail } from "react-icons/hi"
import { BiMessageAltDetail } from "react-icons/bi"
import { BsFillPersonFill } from "react-icons/bs";
import { FaRobot } from "react-icons/fa"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from "../config";
import Loading from "../components/Loading"
import { motion } from "framer-motion";

const op1 = Math.floor(Math.random() * 20 + 1)
const op2 = Math.floor(Math.random() * 10 + 1)

export default function Contact() {

    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const [state, setState] = useState("unsent")
    const [loading, setLoading] = useState(false)
    const errorBot = {
        ro: 'Rezultatul operației este greșit',
        en: "The sum's result is wrong"
    }
    const error = {
        ro: 'Toate câmpurile sunt obligatorii',
        en: 'All fields are required'
    }
    const success = {
        ro: 'Mesajul a fost trimis',
        en: 'The message was sent'
    }
    const [formData, setFormData] = useState({
        name: '',
        phoneNr: '',
        email: '',
        message: '',
        antiBot: ''
    })
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [errorState, setErrorState] = useState({
        name: false,
        phoneNr: false,
        email: false,
        message: false,
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!formData.name) setErrorState({ ...errorState, name: true })
        if (!formData.phoneNr) setErrorState({ ...errorState, phoneNr: true })
        if (!formData.email) setErrorState({ ...errorState, email: true })
        if (!formData.message) setErrorState({ ...errorState, message: true })
        if (!formData.name || !formData.phoneNr || !formData.email || !formData.message) {
            toast.error(error[lang.toLowerCase()])
        }
        else if (op1 + op2 !== Number(formData.antiBot))
            toast.error(errorBot[lang.toLowerCase()])
        else {
            setLoading(true)
            await axiosInstance.post('/users/sendMessage', { name: formData.name, email: formData.email, phoneNr: formData.phoneNr, message: formData.message, lang })
            toast.success(success[lang.toLowerCase()])
            setLoading(false)
            setState('sent')
        }
    }

    const textAnim = {
        offScreen: {
            y: 100,
            opacity: 0
        },
        onScreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.6,
            }
        }
    }

    return (
        <Box id="contact">
            <VStack width="100%">
                <Box m={[24, , 8]} width={["90%", , "50%"]} position="relative">
                    <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1197.2183818224923!2d26.37087053470335!3d46.93198867181193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473555ca04cc30d9%3A0xcd3855b501678189!2sGrand%20Hotel%20Ceahl%C4%83u!5e1!3m2!1sen!2sro!4v1657284592509!5m2!1sen!2sro" width="100%" height="450" allowFullScreen={true} loading="eager" referrerPolicy="no-referrer-when-downgrade" />
                    <Box position="absolute" bg="black.jon" color="white.cultured" bottom={[-50, , 5]} left={[-3, , -100]} p={8} borderRadius={10}>
                        <VStack>
                            <Heading>{lang === 'RO' ? 'Date de contact' : 'Contact Information'}</Heading>
                            <motion.div initial={textAnim.offScreen} whileInView={textAnim.onScreen} viewport={{ once: true }}>
                                <Button fontSize={['lg', , '2xl']} variant="ghost" _hover={{ border: '2px solid', borderColor: 'accents.manhattan' }}><Icon fontSize="3vh" mr={1} as={MdLocalPhone} /><a href={1===1 ? "tel:0233219090" : "tel:0233219990"}>{/*0233 219 990*/}0233 219 090</a></Button>
                            </motion.div>
                            <motion.div initial={textAnim.offScreen} whileInView={textAnim.onScreen} viewport={{ once: true }}>
                                <Button fontSize={['lg', , '2xl']} onClick={() => window.open('https://mail.google.com/mail/u/0/#inbox?compose=new')} variant="ghost" _hover={{ border: '2px solid', borderColor: 'accents.manhattan' }}><Icon fontSize="3vh" mr={1} as={HiMail} />office@hotelceahlau.ro</Button>
                            </motion.div>
                            <motion.div initial={textAnim.offScreen} whileInView={textAnim.onScreen} viewport={{ once: true }}>
                                <Button fontSize={['lg', , '2xl']} onClick={onOpen} mt={2} variant="ghost" _hover={{ border: '2px solid', borderColor: 'accents.manhattan' }}><Icon fontSize="3vh" mr={1} as={BiMessageAltDetail} />{lang === 'RO' ? 'Trimite Mesaj' : 'Send Message'}</Button>
                            </motion.div>
                        </VStack>
                    </Box>
                </Box>
            </VStack>
            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ToastContainer pauseOnHover theme="colored" style={{
                        marginTop: '150px'
                    }} />
                    {
                        loading ? (
                            <Loading />
                        ) : (
                            <Box>
                                <ModalCloseButton onClick={onClose} />
                                <ModalBody p={16}>
                                    <Heading mb={2} textAlign="center">{lang === 'RO' ? 'Formular Contact' : 'Contact Form'}</Heading>
                                    <form onSubmit={handleSubmit}>
                                        <VStack>
                                            <Box w="80%">
                                                <VStack fontSize={['lg', , '2xl']}>
                                                    <Text color={errorState.name && 'red'} pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Nume' : 'Name'}:</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><BsFillPersonFill fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} isInvalid={errorState.name} value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="text" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>

                                                    <Text color={errorState.phoneNr && 'red'} pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Telefon' : 'Phone Number'}:</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><MdLocalPhone fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} isInvalid={errorState.phoneNr} value={formData.phoneNr} onChange={(e) => setFormData({ ...formData, phoneNr: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="tel" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>

                                                    <Text color={errorState.email && 'red'} pt={4} alignSelf="flex-start">Email:</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><HiMail fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} isInvalid={errorState.email} value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="email" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>

                                                    <Text color={errorState.message && 'red'} pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Mesaj' : 'Message'}:</Text>
                                                    <Textarea fontSize={['lg', , '2xl']} isInvalid={errorState.message} value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} borderStyle="solid" fontWeight="bold" borderWidth={2} borderColor="accents.manhattan" required size='lg' maxH="150px" focusBorderColor="black.jon" />

                                                    <Text pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Rezolvă operația' : 'Solve'}: {op1 + " + " + op2}</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><FaRobot fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} value={formData.antiBot} onChange={(e) => setFormData({ ...formData, antiBot: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="email" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>
                                                </VStack>
                                            </Box>
                                        </VStack>
                                        <Button isDisabled={state==='sent' ? true : false} onClick={handleSubmit} className="btn-group" bg="black.dark" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2} type="submit" rightIcon={state === 'unsent' ? <EmailIcon /> : <CheckIcon />} fontSize={["lg", , "2xl"]} ml="10%" mt={4}>
                                            {state==='unsent' ? 
                                                lang === 'RO' ? 'Trimite' : 'Send'
                                                : lang==='RO' ? 'Trimis' : 'Sent'
                                            }
                                        </Button>
                                    </form>
                                </ModalBody>
                            </Box>
                        )
                    }
                </ModalContent>
            </Modal>
        </Box>
    )

}