import Nav from "./Navbar";
import { Box } from "@chakra-ui/react";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import Loader from './Loader'
import { motion } from "framer-motion";

export default function Layout({ title, children }) {
    const [loading, setLoading] = useState(true)

    setInterval(() => {
        setLoading(false)
    }, 3000)
    const contentAnim = {
        appear: {
            opacity: 1,
            transition: {
                duration: 0.7,
            }
        },
        loading: {
            opacity: 0,
        }
    }
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        if (window)
            window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <head>
                <title>{title}</title>
            </head>
            <Box position="relative" m='0' minH='100vh' _after={{ content: '""', display: "block", height: "40px" }} bgColor="white.cultured" bgImage={(title === "Înregistrare" || title === "Signup" || title === "Intră în cont" || title === "Login" || title === "Activare Cont" || title === "Account Activation" || title === "Cont" || title === "Account" || title === "Resetare Parolă" || title === "Reset Password" || title === "Rezervare" || title === "Rezervările Tale" || title === "Your Bookings" || title === "Plată Efectuată" || title === "Payment Successful" || title==='Pagină Inexistentă' || title==='Error 404') ? "url('/images/bg.svg')" : (title === 'Rezervări' || title === 'Booking') ? "url('/images/interior/hol/2.jpg')" : (title === 'Cariere' || title === 'Jobs') && "url('/images/interior/hol/3.jpg')"} bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
                <Loader />
                <motion.div animate={!loading ? "appear" : 'loading'} variants={contentAnim}>
                    <Nav title={title}>
                        <Box>{children}</Box>
                    </Nav>
                    <Box style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        height: '40px',
                    }}
                    >
                        <Footer />
                    </Box>
                </motion.div>
            </Box >
        </>
    )
}