import { Box, Button, Heading, Image, Stack, StackDivider, Text, VStack, Icon, useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, InputGroup, InputLeftElement, Input, Textarea } from "@chakra-ui/react";
import Layout from "../components/Layout";
import React, { useCallback, useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons"
import { motion } from "framer-motion"
import { EmailIcon, CheckIcon } from "@chakra-ui/icons";
import { MdLocalPhone } from "react-icons/md"
import { HiMail } from "react-icons/hi"
import { BsFillPersonFill } from "react-icons/bs";
import { FaRobot } from "react-icons/fa"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from "../config";
import Loading from "../components/Loading"
import Gallery from "../components/Gallery";


const op1 = Math.floor(Math.random() * 20 + 1)
const op2 = Math.floor(Math.random() * 10 + 1)

export default function Evenimente({ innerWidth }) {

    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [state, setState] = useState("unsent")
    const [y, setY] = useState(window.scrollY)
    const [loading, setLoading] = useState(false)
    const errorBot = {
        ro: 'Rezultatul operatiei este gresit',
        en: 'The sum result is wrong'
    }
    const error = {
        ro: 'Toate campurile sunt obligatorii',
        en: 'All fields are required'
    }
    const success = {
        ro: 'Mesajul a fost trimis',
        en: 'The message was sent'
    }
    const [formData, setFormData] = useState({
        name: '',
        phoneNr: '',
        email: '',
        message: '',
        antiBot: ''
    })
    const handleNavigation = useCallback((e) => {
        const window = e.currentTarget;
        setY(window.scrollY);
    }, [y])

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    const arrowAnim = {
        open: {
            x: [-10, 0],
            opacity: 1
        },
        close: {
            opacity: 0
        }
    }

    const [errorState, setErrorState] = useState({
        name: false,
        phoneNr: false,
        email: false,
        message: false,
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!formData.name) setErrorState({ ...errorState, name: true })
        if (!formData.phoneNr) setErrorState({ ...errorState, phoneNr: true })
        if (!formData.email) setErrorState({ ...errorState, email: true })
        if (!formData.message) setErrorState({ ...errorState, message: true })
        if (!formData.name || !formData.phoneNr || !formData.email || !formData.message) {
            toast.error(error[lang.toLowerCase()])
        }
        else if (op1 + op2 !== Number(formData.antiBot))
            toast.error(errorBot[lang.toLowerCase()])
        else {
            await axiosInstance.post('/users/sendMessage', { name: formData.name, email: formData.email, phoneNr: formData.phoneNr, message: formData.message, lang })
            toast.success(success[lang.toLowerCase()])
            setState('sent')
        }
    }
    const images = [
        {
            url: 'images/interior/events/1.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/4.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/3.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/2.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/5.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/6.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/7.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/8.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/9.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/10.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/11.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/12.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/13.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/14.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/15.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/16.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/20.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/18.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/19.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/17.jpg',
            caption: "",
        },
        {
            url: 'images/interior/events/21.jpg',
            caption: "",
        },
    ]
    return (
        <Layout title={lang === 'RO' ? "Evenimente" : 'Events'}>
            <Box position='fixed' zIndex={-2} left={0} top={innerWidth < 950 ? "12vh" : 0} width="100%" height="75vh">
                <Image transform={y > 80 ? "scale(1.5)" : ''} transitionDuration="0.5s" src={images[0].url} width="100%" height={innerWidth < 950 ? '53vh' : '100vh'} />
            </Box>
            <Box mt={innerWidth < 950 ? '57vh' : '75vh'} bg="black.dark" color="white.cultured" p={8} width="100%" ml="auto" mr="auto" borderRadius={5} className="bg">
                <Stack direction={innerWidth < 950 ? 'column' : 'row'} divider={<StackDivider borderColor="accents.raw-sienna" />} justifyContent="space-evenly">
                    <VStack textAlign="center">
                        <Heading fontSize={['5xl', , '6xl']}>{lang === 'RO' ? 'Organizăm' : 'We organize'}</Heading>
                        <Heading fontSize={['5xl', , '6xl']}>{lang === 'RO' ? 'evenimente' : 'events'}</Heading>
                    </VStack>
                    <VStack>
                        <Text fontSize={['xl', , '3xl']} textAlign="center">{lang === 'RO' ? 'Organizăm nunți, botezuri, pretreceri, conferințe și multe altele' : 'We organize weddings, baptisms, meetings and more'}</Text>
                        <Button className="btn-group" p={6} fontSize={['xl', , '3xl']} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2} onClick={onOpen}>{lang === 'RO' ? 'Contactează-ne' : 'Contact us'}</Button>
                    </VStack>
                </Stack>
            </Box>
            <Gallery images={images} innerWidth={innerWidth}/>
            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ToastContainer pauseOnHover theme="colored" style={{
                        marginTop: '150px'
                    }} />
                    {
                        loading ? (
                            <Loading />
                        ) : (
                            <Box>
                                <ModalCloseButton onClick={onClose} />
                                <ModalBody p={16}>
                                    <Heading mb={2} textAlign="center">{lang === 'RO' ? 'Formular Contact' : 'Contact Form'}</Heading>
                                    <form onSubmit={handleSubmit}>
                                        <VStack>
                                            <Box w="80%">
                                                <VStack fontSize={['lg', , '2xl']}>
                                                    <Text color={errorState.name && 'red'} pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Nume' : 'Name'}:</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><BsFillPersonFill fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} isInvalid={errorState.name} value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="text" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>

                                                    <Text color={errorState.phoneNr && 'red'} pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Telefon' : 'Phone Number'}:</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><MdLocalPhone fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} isInvalid={errorState.phoneNr} value={formData.phoneNr} onChange={(e) => setFormData({ ...formData, phoneNr: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="tel" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>

                                                    <Text color={errorState.email && 'red'} pt={4} alignSelf="flex-start">Email:</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><HiMail fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} isInvalid={errorState.email} value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="email" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>

                                                    <Text color={errorState.message && 'red'} pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Mesaj' : 'Message'}:</Text>
                                                    <Textarea fontSize={['lg', , '2xl']} isInvalid={errorState.message} value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} borderStyle="solid" fontWeight="bold" borderWidth={2} borderColor="accents.manhattan" required size='lg' maxH="150px" focusBorderColor="black.jon" />

                                                    <Text pt={4} alignSelf="flex-start">{lang === 'RO' ? 'Rezolvă operația' : 'Solve'}: {op1 + " + " + op2}</Text>
                                                    <InputGroup>
                                                        <InputLeftElement pt={2}><FaRobot fontSize="3vh" /></InputLeftElement>
                                                        <Input fontSize={['lg', , '2xl']} value={formData.antiBot} onChange={(e) => setFormData({ ...formData, antiBot: e.target.value })} color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" type="email" required size='lg' focusBorderColor="black.jon" />
                                                    </InputGroup>
                                                </VStack>
                                            </Box>
                                        </VStack>
                                        <Button isDisabled={state === 'sent' ? true : false} onClick={handleSubmit} className="btn-group" bg="black.dark" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2} type="submit" rightIcon={state === 'unsent' ? <EmailIcon /> : <CheckIcon />} fontSize={["lg", , "2xl"]} ml="10%" mt={4}>
                                            {state === 'unsent' ?
                                                lang === 'RO' ? 'Trimite' : 'Send'
                                                : lang === 'RO' ? 'Trimis' : 'Sent'
                                            }
                                        </Button>
                                    </form>
                                </ModalBody>
                            </Box>
                        )
                    }
                </ModalContent>
            </Modal>
        </Layout>
    )
}