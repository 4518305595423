import { Box, Button, Heading, Image, List, ListIcon, ListItem, Text, VStack } from "@chakra-ui/react";
import { BsArrowsAngleExpand } from "react-icons/bs"
import { MdPeople } from "react-icons/md"
import { IoIosPricetags } from "react-icons/io"
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function RoomCard({ innerWidth, img, type, desc, area, maxCount, price, lang }) {
    const firstLetterUpperCase = (str) => {
        const arr = str.split("-")
        if (arr.length < 2) return String(str[0]).toUpperCase() + str.slice(1)
        else return String((arr[0])[0]).toUpperCase() + arr[0].slice(1) + " " + String((arr[1])[0]).toUpperCase() + arr[1].slice(1)
    }

    const hAnim = {
        offScreen: {
            y: 100,
            x: 100,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    }

    const textAnim = {
        offScreen: {
            y: 50,
            x: 50,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 1.2
            }
        }
    }

    const listAnim = {
        offScreen: {
            y: 100,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 1.6
            }
        }
    }

    const buttonAnim = {
        offScreen: {
            y: 50,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    }

    return (
        <VStack width={innerWidth > 1220 ? '20%' : '60%'} height={innerWidth > 1220 ? '' : '100%'} ml={innerWidth >= 950 ? 1 : 'auto'} mr={innerWidth >= 950 ? 1 : 'auto'}>
            <Image pb={6} ml="auto" mr="auto" src={img} width="100%" loading="eager" />
            <VStack pl={4} justifyContent="space-between" alignSelf="flex-start" height='100%' borderLeftStyle="solid" borderLeftWidth={3} borderLeftColor="accents.manhattan">
                <Box>
                    <Box alignSelf='flex-start'>
                        <motion.div initial={innerWidth >= 950 && hAnim.offScreen} whileInView={innerWidth >= 950 && hAnim.onScreen} viewport={{ once: true }}>
                            <Heading fontSize={['5xl', , '6xl']}>{firstLetterUpperCase(type)}</Heading>
                        </motion.div>
                    </Box>
                    <motion.div initial={innerWidth >= 950 && listAnim.offScreen} whileInView={innerWidth >= 950 && listAnim.onScreen} viewport={{ once: true }}>
                        <List spacing={3} alignSelf="flex-start" fontSize={['lg', , '2xl']}>
                            <ListItem>
                                <ListIcon as={BsArrowsAngleExpand} />
                                {area} m<sup>2</sup>
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdPeople} />
                                {lang === 'RO' ? `maxim ${maxCount} ${maxCount === 1 ? 'persoană' : 'persoane'}` : `maximum ${maxCount} ${maxCount === 1 ? 'person' : 'people'}`}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={IoIosPricetags} />
                                {price} RON /<sub>{lang === 'RO' ? 'noapte' : 'night'}</sub>
                            </ListItem>
                            <ListItem>
                                <Text fontSize={['md', , 'xl']}>{desc}</Text>
                            </ListItem>
                            <ListItem fontSize={['sm', , 'lg']}>
                                <Text>{lang === 'RO' ? 'Taxa promovare turistica: 2 RON / camera' : 'Tourism promovation tax: 2 RON / room'}</Text>
                            </ListItem>
                        </List>
                    </motion.div>
                </Box>
                <Box alignSelf='flex-start'>
                    <motion.div initial={innerWidth >= 950 && buttonAnim.offScreen} whileInView={innerWidth >= 950 && buttonAnim.onScreen} viewport={{ once: true }}>
                        <Button fontSize={['lg', , '2xl']} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.manhattan" borderWidth={2}><Link to={`/camere/${type}`}>{lang === 'RO' ? 'Vezi Detalii' : 'See Room'}</Link></Button>
                    </motion.div>
                </Box>
            </VStack>
        </VStack>
    )
}