import { Box, Grid, GridItem, Image, Img } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

export default function Gallery({ images, innerWidth }) {
    const [galleryRendering, setGalleryRendering] = useState([]);
    useEffect(() => {
        for (let i = 0; i < images.length; i += 3) {
            setGalleryRendering(prevState => [...prevState, (
                <>
                    <GridItem key={i} colSpan={1} rowSpan={2} mb={8}>
                        <Image src={images[i].url} width="100%" height="100%" objectFit="cover" borderRadius={10} />
                    </GridItem>
                    <GridItem key={i + 1} colSpan={1} rowSpan={1} mb={8}>
                        <Image src={images[i + 1].url} width="100%" height="100%" objectFit="cover" borderRadius={10} />
                    </GridItem>
                    <GridItem key={i + 2} colSpan={1} rowSpan={1} mb={8}>
                        <Image src={images[i + 2].url} width="100%" height="100%" objectFit="cover" borderRadius={10} />
                    </GridItem>
                </>
            )])
        }
    }, [])
    return (
        <Box width="100%" pt={8} pb={8} pl={16} pr={16} bgColor="white.cultured" bgImage="url('/images/bg.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
            <Grid columnGap={8} width="100%" templateColumns={innerWidth>=1200 ? "repeat(3, 1fr)" : innerWidth>=600 ? "repeat(2, 1fr)": "repeat(1, 1fr)"} templateRows={innerWidth>=600 ? "repeat(2, 30vh)": "repeat(1, 1fr)"} >
                {
                    galleryRendering.map((item, index) => {
                        if (index < images.length / 3)
                            return item
                    })
                }
            </Grid>
        </Box>
    )
}
