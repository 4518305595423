import { Box, Heading, Image, Input, InputGroup, InputLeftElement, Text, VStack, Icon, Button, HStack, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Stack } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import Layout from "../components/Layout"
import { axiosInstance } from "../config"
import { getCookie } from "../utils/authHelpers"
import Loading from "../components/Loading"
import Sidebar from "../components/Sidebar"
import { BsFillPersonFill } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import { isAuth } from "../utils/authHelpers"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export default function Account({ innerWidth }) {
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
    const [updatedUser, setUpdatedUser] = useState({
        name: '',
        picture: ''
    })
    const [lang, setLang] = useState(window.localStorage.lang)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        async function getUser() {
            if (window) {
                const token = getCookie('token')
                try {
                    setLoading(true)
                    const data = (await axiosInstance.post('/users/getUser', { token })).data
                    setUser(data.user)
                    setUpdatedUser({ ...updatedUser, name: data.user.name })
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    window.location.href = '/'
                }
            }
        }
        if (!isAuth()) {
            window.location.href = '/logare'
        } else {
            getUser()
        }
    }, [])

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const extError={
        ro:'Documentul trebuie să fie de tipul png sau jpg',
        en:'The document must be a png or jpg'
    }
    
    const docError={
        ro:'Documentul poate avea maxim 2.5 MB',
        en:'The document can have a maximum of 2.5 MB'
    }

    const handleImgChange = (e) => {
        const img = e.target.files[0]
        if (img && img.size < 2499000) {
            const ext = img.name.split('.')[1]
            if (ext !== 'png' && ext !== 'jpg') {
                toast.error(extError[lang.toLowerCase()])
            } else {
                const reader = new FileReader()
                reader.readAsDataURL(img)
                reader.addEventListener('load', () => {
                    setUpdatedUser({ ...updatedUser, picture: reader.result })
                })
            }
        } else {
            toast.error(docError[lang.toLowerCase()])
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            await axiosInstance.put('/users/updateUser', { token: getCookie('token'), updatedUser })
                .then(res => {
                    setLoading(false)
                    toast.success(res.data.message[lang.toLowerCase()])
                })
        } catch (err) {
            setLoading(false)
            toast.error(err.response.data.error[lang.toLowerCase()])
        }
    }

    const handleResetPassword = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            await axiosInstance.post('/users/forgetPassword', { email: user.email, lang })
            setLoading(false)
            onClose()
        } catch (err) {
            setLoading(false)
        }
    }
    return (
        <Layout title={lang === 'RO' ? "Cont" : 'Account'}>
            <ToastContainer pauseOnHover theme="colored" style={{
                marginTop: '150px'
            }} />
            {
                loading ? (
                    <Loading />
                ) : (
                    <Sidebar innerWidth={innerWidth}>
                        <VStack overflow='hidden' width="70%" fontSize={innerWidth >= 1230 ? '2xl' : innerWidth >= 950 ? '2xl' : innerWidth >= 640 ? '2xl' : innerWidth >= 555 ? 'lg' : innerWidth >= 430 ? 'md' : innerWidth >= 385 ? 'xs' : 'xs'} className="registration" mt={40} mb={8} p={[4, , 16]}>
                            <Heading textAlign='center' fontSize={['4xl',, '5xl']}>{lang==='RO' ? `Bun venit ${user.name}!` : `Welcome back ${user.name}!`}</Heading>
                            <Image src={user.picture} loading="lazy" borderRadius="50%" width={['10vh', , '25vh']} height={["10h", , '25vh']} />
                            <Box pb={8} width="90%">
                                <form>
                                    <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Nume' : 'Name'}:</Text>
                                    <InputGroup>
                                        <InputLeftElement children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={BsFillPersonFill} />} />
                                        <Input fontSize={['lg', , '2xl']} value={updatedUser.name} onChange={(e) => setUpdatedUser({ ...updatedUser, name: e.target.value })} type="text" name="name" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                                    </InputGroup>

                                    <Text fontSize={['lg', , '2xl']} pt={4}>Email:</Text>
                                    <InputGroup>
                                        <InputLeftElement children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={MdEmail} />} />
                                        <Input fontSize={['lg', , '2xl']} disabled defaultValue={user.email} type="email" name="email" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                                    </InputGroup>

                                    <Text fontSize={['lg', , '2xl']} pt={4}>{lang === 'RO' ? 'Poză' : 'Profile Picture'}:</Text>
                                    <InputGroup>
                                        <Stack direction={['column', , 'row']} justifyContent="space-between">
                                            <Button fontSize={['lg', , '2xl']} position="relative" zIndex={1} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Alege poza' : 'Choose picture'}</Button>
                                            <Input width="20vh" cursor="pointer" position="absolute" zIndex={2} opacity={0} onChange={(e) => handleImgChange(e)} type="file" accept="image/png, image/jpeg" />
                                            <Image src={updatedUser.picture || '/images/stock.png'} zIndex={2} width={['10vh', , '15vh']} height={["10vh", , '15vh']} borderRadius="50%" />
                                        </Stack>
                                    </InputGroup>
                                </form>
                            </Box>
                            <Stack direction={['column', , 'row']} justifyContent="space-between" pl={2} pr={2}>
                                <Button fontSize={['lg', , '2xl']} onClick={handleSubmit} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Actualizare Cont' : 'Update Account'}</Button>
                                <Button fontSize={['lg', , '2xl']} onClick={onOpen} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Resetare Parolă' : 'Reset Password'}</Button>
                            </Stack>
                            <Modal isOpen={isOpen} onClose={onClose} size='lg'>
                                <ModalOverlay />
                                <ModalContent bg="red.300">
                                    <ModalHeader textAlign="center" fontSize={['5xl', , '6xl']}>{lang === 'RO' ? 'Ești sigur că vrei să resetezi parola?' : 'Are you sure you want to reset password?'}</ModalHeader>
                                    <ModalBody>
                                        <HStack justifyContent="center" spacing={12}>
                                            <Button fontSize={['lg', , '2xl']} bg="red" onClick={handleResetPassword}>{lang === 'RO' ? 'Resetare' : 'Reset'}</Button>
                                            <Button fontSize={['lg', , '2xl']} onClick={onClose}>{lang === 'RO' ? 'Anulare' : 'Cancel'}</Button>
                                        </HStack>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </VStack>
                    </Sidebar>
                )
            }
        </Layout>
    )
}