import { extendTheme } from '@chakra-ui/react';

const colors = {
  'white':{
    'cultured':'#F7F7F7',
  },
  'black':{
    "jon":"#494041",
    "dark":"#2F292A"
  },
  'grey':{
    "link-water":"#D4DBE5",
    "obs":"#7A7979"
  },
  'accents':{
    "raw-sienna":"#D5873F",
    "manhattan":"#E4AD77"
  }
}
const components = {
  Container: {
    baseStyle: {
      maxW: 'container.xl',
    },
  },
};
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({ colors, components, config});

export default theme;