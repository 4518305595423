import Layout from "../components/Layout";
import { Box, Button, Heading, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Text, VStack } from "@chakra-ui/react"
import { MdEmail } from "react-icons/md"
import { RiLockPasswordFill } from "react-icons/ri"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { useCallback, useEffect, useState } from "react";
import Loading from "../components/Loading"
import { axiosInstance } from "../config"
import cookie from "js-cookie"
import Google from "../components/Google";
import { isAuth } from "../utils/authHelpers";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
    const [loading, setLoading] = useState(false)
    const [account, setAccount] = useState({
        email: '',
        password: '',
    })
    const [visible, setVisible] = useState(false)
    const [lang, setLang] = useState(window.localStorage.lang)

    useEffect(() => {
        if (isAuth()) window.location.href = '/'
    }, [])

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const handlePasswordVisible = () => {
        if (visible) setVisible(false)
        else setVisible(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const data = (await axiosInstance.post('/auth/login', account)).data
            if (window !== undefined) {
                cookie.set('token', data.token, { expires: 1, secure: true })
            }
            setLoading(false)
            window.location.href = '/cont'
        } catch (err) {
            setLoading(false)
            toast.error(err.response.data.error[lang.toLowerCase()])
        }
    }

    return (
        <Layout title={lang==='RO' ? "Intră în cont" : "Login"}>
            <ToastContainer pauseOnHover theme="colored" style={{
                marginTop: '150px'
            }} />
            {
                loading ? (
                    <Loading />
                ) : (
                    <VStack mt={40} mb={6} width="fit-content" ml="auto" mr="auto" p={[6, , 16]} borderRadius={8} className="registration">
                        <Heading fontSize={['5xl', , '6xl']} mb={2} textAlign='center'>{lang==='RO' ? "Intră în cont" : "Login"}</Heading>
                        <Box pb={6}>
                            <form>
                                <Text fontSize={['lg', , '2xl']} pt={4}>Email:</Text>
                                <InputGroup>
                                    <InputLeftElement children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={MdEmail} />} />
                                    <Input fontSize={['lg', , '2xl']} value={account['email']} onChange={(e) => setAccount({ ...account, ['email']: e.target.value })} type="email" name="email" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                                </InputGroup>

                                <Text fontSize={['lg', , '2xl']} pt={4}>{lang==='RO' ? 'Parola' : 'Password'}:</Text>
                                <InputGroup>
                                    <InputLeftElement children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={RiLockPasswordFill} />} />
                                    <Input fontSize={['lg', , '2xl']} autoComplete="off" value={account['password']} onChange={(e) => setAccount({ ...account, ['password']: e.target.value })} type={visible ? 'text' : 'password'} name="password" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                                    <InputRightElement cursor="pointer" onClick={handlePasswordVisible} children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={!visible ? AiFillEye : AiFillEyeInvisible} />} />
                                </InputGroup>
                                <Link to='/resetare-parola'><Text fontSize={['md', , 'xl']}>{lang==='RO' ? 'Ți-ai uitat parola' : 'Forgot password'}?</Text></Link>
                            </form>
                        </Box>

                        <Button fontSize={['lg', , '2xl']} onClick={handleSubmit} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang==='RO' ? 'Conectare' : 'Login'}</Button>
                        <Link to="/inregistrare"><Text fontSize={['md', , 'xl']}>{lang==='RO' ? 'Nu ai cont? Creează-ți unul aici' : "Don't have an account? Signup here "}</Text></Link>
                        <Text fontSize={['md', , 'xl']} className="textLines">{lang==='RO' ? 'Sau' : 'Or'}</Text>
                        <Google />
                    </VStack>
                )
            }
        </Layout>
    )
}