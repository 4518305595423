import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import useFetch from "../hooks/useFetch";
import Loading from "./Loading"

export default function Google() {
    const { handleGoogle, loading, error } = useFetch();
    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleGoogle,
            });

            window.google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
                type: "standard",
                theme: "filled_black",
                size: "large",
                text: "signup_with",
            });
        }
    }, [handleGoogle]);
    return (
        <div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {
                loading ? (
                    <Loading />
                ) : (
                    <Box mb={8} id="signUpDiv" data-text="signup_with"></Box>
                )
            }
        </div>
    )
}