import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import theme from './utils/theme'
import Home from "./pages/Home"
import Booking from "./pages/Booking"
import Evenimente from "./pages/Evenimente"
import Camere from './pages/Camere';
import Error404 from './pages/ErrPage';
import Room from "./pages/TipCamera"
import Signup from './pages/Signup';
import Login from './pages/Login';
import Account from './pages/Account';
import AccountActivation from './pages/AccountActivation';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import RezervationById from './pages/RezervationById';
import Rezervation from './pages/Rezervations';
import PaymentSuccess from './pages/PaymentSuccess';
import Jobs from './pages/Jobs';
import Terms from './pages/Terms';
import DataProtection from './pages/DataProtection';

function App() {
  function getWidth() {
    const { innerWidth: width } = window
    return width
  }

  const [innerWidth, setInnerWidth] = useState(getWidth())

  useEffect(() => {
    if (window) {
      if (!window.localStorage.getItem('lang'))
        window.localStorage.setItem('lang', 'RO')
    }
  }, [])

  useEffect(() => {
    function handleResize() {
      setInnerWidth(getWidth())
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  })

  const roomTypes = [
    {
      type: 'single',
      price: 170,
      desc: {
        en: '',
        ro: ''
      },
      area: 35,
      maxCount: 1
    },
    {
      type: 'double',
      price: 280,
      desc: {
        en: '',
        ro: ''
      },
      area: 40,
      maxCount: 2
    },
    {
      type: 'king-size',
      price: 280,
      desc: {
        en: 'Double in single mode/1 person - 200 RON',
        ro: 'Dublă în regim single/1 persoană - 200 RON'
      },
      area: 40,
      maxCount: 2
    },
    {
      type: 'apartment',
      price: 400,
      desc: {
        en: '',
        ro: ''
      },
      area: 60,
      maxCount: 3
    }
  ]

  const roomsImages = {
    single: [
      '/images/interior/camere/single/1.jpg',
      '/images/interior/camere/single/2.jpg',
      '/images/interior/camere/single/3.jpg',
    ],
    double: [
      '/images/interior/camere/double/1.jpg',
      '/images/interior/camere/double/2.jpg',
      '/images/interior/camere/double/3.jpg',
    ],
    'king-size': [
      '/images/interior/camere/kingSize/1.jpg',
      '/images/interior/camere/kingSize/2.jpg',
      '/images/interior/camere/kingSize/3.jpg',
    ],
    apartment: [
      '/images/interior/camere/apartment/1.jpg',
      '/images/interior/camere/apartment/2.jpg',
      '/images/interior/camere/apartment/3.jpg',
      '/images/interior/camere/apartment/4.jpg',
      '/images/interior/camere/apartment/5.jpg',
    ]
  }
  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home innerWidth={innerWidth} />} />
            <Route path="evenimente" element={<Evenimente innerWidth={innerWidth} />} />
            <Route path='/camere' element={<Camere innerWidth={innerWidth} roomTypes={roomTypes} roomsImages={roomsImages} />} />
            <Route path='camere/single' element={<Room innerWidth={innerWidth} title={'Single'} desc={roomTypes[0].desc} area={roomTypes[0].area} price={roomTypes[0].price} maxCount={roomTypes[0].maxCount} img={roomsImages['single']} />} />
            <Route path='camere/double' element={<Room innerWidth={innerWidth} title={'Double'} desc={roomTypes[1].desc} area={roomTypes[1].area} price={roomTypes[1].price} maxCount={roomTypes[1].maxCount} img={roomsImages['double']} />} />
            <Route path='camere/king-size' element={<Room innerWidth={innerWidth} title={'King Size'} desc={roomTypes[2].desc} area={roomTypes[2].area} price={roomTypes[2].price} maxCount={roomTypes[2].maxCount} img={roomsImages['king-size']} />} />
            <Route path='camere/apartment' element={<Room innerWidth={innerWidth} title={'Apartment'} desc={roomTypes[3].desc} area={roomTypes[3].area} price={roomTypes[3].price} maxCount={roomTypes[3].maxCount} img={roomsImages['apartment']} />} />
            <Route path="rezervari" element={<Booking innerWidth={innerWidth} />} />
            <Route path="/inregistrare" element={<Signup innerWidth={innerWidth} />} />
            <Route path="/logare" element={<Login innerWidth={innerWidth} />} />
            <Route path="/activare-cont/:token" element={<AccountActivation innerWidth={innerWidth} />} />
            <Route path="/cont" element={<Account innerWidth={innerWidth} />} />
            <Route path="/cont/rezervari" element={<Rezervation innerWidth={innerWidth} />} />
            <Route path="/resetare-parola" element={<ForgetPassword innerWidth={innerWidth} />} />
            <Route path="/resetare-parola/:token" element={<ResetPassword innerWidth={innerWidth} />} />
            <Route path="/rezervare/:bookingId" element={<RezervationById innerWidth={innerWidth} />} />
            <Route path="/completion" element={<PaymentSuccess innerWidth={innerWidth} />} />
            {/*<Route path='/cariere' element={<Jobs innerWidth={innerWidth} />} />*/}
            <Route path='/termeni-si-conditii' element={<Terms innerWidth={innerWidth} />} />
            <Route path='/protectia-datelor' element={<DataProtection innerWidth={innerWidth} />} />
            <Route path="/404" element={<Error404 />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </ChakraProvider>
  );
}

export default App;
