import { useCallback, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Box, Heading, List, ListIcon, ListItem, OrderedList, Text, UnorderedList } from "@chakra-ui/react";
import { ArrowForwardIcon } from '@chakra-ui/icons'


export default function DataProtection({ innerWidth }) {
    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);
    return (
        <Layout title={lang === 'RO' ? 'Protecția datelor' : 'Personal data protection'}>
            <Box fontSize="lg" pt={innerWidth >= 900 ? 40 : 36} pb={8} pl={12} pr={12} textAlign='justify'>
                <Heading textAlign='center'>Politica de confidențialitate- GRAND HOTEL CEAHLAU S.A.</Heading>
                <ol type="I">
                    <li>Informații generale</li>
                    <Text>
                        Confidențialitatea datelor dumneavoastră cu caracter personal reprezintă una dintre preocupările principale ale proprietarului site-ului grandhotelceahlau.ro, în calitate de operator de date.
                        Acest document are rolul de a vă informa cu privire la prelucrarea datelor dumneavoastră cu caracter personal, în contextul utilizării paginii de internet grandhotelceahlau.ro. („Site-ul”)
                    </Text>
                    <li>Contextul economic si legislația de baza:</li>
                    <UnorderedList>
                        <ListItem>
                            Parlamentul European și Consiliul au adoptat, în data de 27 aprilie 2016, Regulamentul (UE) 2016/679 privind protecţia persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulaţie a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor – RGPD). Regulamentul (UE) 2016/679 a fost publicat în Jurnalul Oficial al Uniunii L119 din 4 mai 2016, iar prevederile lui sunt aplicabile în toate statele membre ale Uniunii Europene, începând cu luna mai 2018
                        </ListItem>
                        <ListItem>
                            Regulamentul (UE) 2016/679 impune un set unic de reguli în materia protecţiei datelor cu caracter personal, înlocuind Directiva 95/46/CE și, implicit, prevederile Legii nr. 677/2001.
                        </ListItem>
                        <ListItem>
                            Regulamentul (UE) 2016/679 pune accent pe transparenţa faţă de persoana vizată și responsabilizarea operatorului de date faţă de modul în care prelucrează datele cu caracter personal. Regulamentul (UE) 2016/679 stabilește o serie de garanţii specifice pentru a proteja cât mai eficient viaţa privată a minorilor, în special în mediul online
                        </ListItem>
                        <ListItem>
                            Regulamentul (UE) 2016/679 consolidează drepturile garantate persoanelor vizate și introduce noi drepturi: dreptul de a fi uitat, dreptul la portabilitatea datelor și dreptul la restricţionarea prelucrării
                        </ListItem>
                        <ListItem>
                            Începând cu 25 mai 2018 Regulamentul 679/27-apr-2016 privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date şi de abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor) (denumit în continuare „GDPR”) este aplicat în toate statele Uniunii Europene.
                        </ListItem>
                        <ListItem>
                            Directiva 2016/680/CE,
                        </ListItem>
                        <ListItem>
                            Directiva 2002/58/CE;
                        </ListItem>
                        <ListItem>
                            Legea nr. 677/2001
                        </ListItem>
                        <ListItem>
                            Legea nr. 190/2018,
                        </ListItem>
                        <ListItem>
                            Legea nr. 506/2004;
                        </ListItem>
                        <ListItem>
                            Codul de procedură civilă;
                        </ListItem>
                        <ListItem>
                            Norma cu privire la accesul, evidenţa şi protecţia turiştilor în structuri de primire turistice din 08.02.2001
                        </ListItem>
                    </UnorderedList>
                    <li>Categoriile de date cu caracter personal prelucrate- Scopurile și temeiurile prelucrării-</li>
                    <Text>
                        Dacă sunteți vizitator al Site-ului, vom prelucra datele dumneavoastră cu caracter personal pe care le furnizați în mod direct în contextul utilizării Site-ului, cum ar fi datele pe care le furnizați în cadrul secțiunii de contact / întrebări / reclamații, în măsura în care ne contactați în acest fel (ACEASTA SECTIUNE ESTE IN LUCRU). Astfel :
                    </Text>
                    <UnorderedList>
                        <ListItem>
                            Datele cu caracter personal Datele dumneavoastră pe care le vom prelucra sunt date cu caracter personal obținute direct de la dumneavoastră sau de la terțe părți care aveau permisiunea de a face schimb de informații cu noi. Prin prezenta Informare vă comunicăm că datele cu caracter personal pe care le prelucrăm pot fi următoarele: datele de identificare şi contact: nume şi prenume, adresa, locul şi data naşterii, cod numeric personal, datele de identificare menţionate în BI /CI/ paşaport sau alte acte de identitate, cetăţenie, e-mail, telefon/fax, cont bancar, adresă de facturare, tipul cardului bancar/ cardului de credit, numele titularului cardului bancar/ cardului de credit, data expirării cardului bancar/ cardului de credit, codul CVC/CVV al cardului bancar, IP-ul în situaţia accesării site-urilor GRAND HOTEL CEAHLAU , opinii şi viziuni (pot include date sensibile), cum ar fi: orice opinii şi viziuni pe care ni le transmiteţi sau orice opinii şi viziuni pe care le postaţi public despre noi pe reţelele de socializare (social media) sau pe care le faceţi cunoscute pe alte canale publice. În majoritatea lor aceste date reprezintă o obligație legală sau contractuală necesară atât pentru încheierea, cât și pentru executarea contractului de prestări servicii hoteliere, nefurnizarea lor putând afecta furnizarea serviciilor contractate sau respectarea obligațiilor noastre legale, inclusiv cele legate de raportare și evidențiere.
                        </ListItem>
                        <ListItem>
                            Societatea va folosi datele cardurilor bancare, cardurilor de credit, conturilor bancare înregistrate cu ocazia rezervărilor de cameră, de săli, organizare evenimente numai în măsura şi pentru perioada de timp necesare pentru exercitarea drepturilor şi îndeplinirea obligaţiilor sale. Datele sunt gestionate de partenerii bancari şi contractuali ai Societăţii. Sunt date cu caracter personal acele informaţii referitoare la o persoană fizică identificată sau identificabilă. O persoană identificabilă este acea persoană care poate fi identificată, direct sau indirect, în mod particular prin referire la un număr de identificare ori la unul sau mai mulţi factori specifici identităţii sale fizice, fiziologice, psihice, economice, culturale sau sociale.
                        </ListItem>
                    </UnorderedList>
                    <Text>Vom colecta datele dumneavoastră cu caracter personal obișnuite, în baza acordului dumneavoastră, atunci când: </Text>
                    <UnorderedList>
                        <ListItem>
                            Se solicită sau se folosesc serviciile hoteliere ale Grand Hotel Ceahlau S.A. (inclusiv organizare evenimente);
                        </ListItem>
                        <ListItem>
                            Se solicită informații în legătură cu un serviciu prin diverse canale;
                        </ListItem>
                        <ListItem>
                            Vizitați sau navigați pe site-ul noastru;
                        </ListItem>
                        <ListItem>
                            Ați acordat permisiunea altor societăți, cum ar fi partenerii noștri comerciali sau asociații noștri, precum și în situația în care transmitem astfel de date unor terți furnizori sau contractanți ai noștri, în măsura în care avem temeiuri legale, de exemplu: instituții financiare bancare, furnizori servicii contabilitate, autorități sau instituții publice, notari, avocați etc;
                        </ListItem>
                        <ListItem>
                            Atunci când datele dvs. cu caracter personal sunt publice;
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        Societatea poate fi contactată prin e-mail. Mesajele sunt gestionate Grand Hotel Ceahlau S.A. până la rezolvarea acestora, iar după soluţionare sunt stocate conform prevederilor legale în vigoare.
                    </Text>
                    <OrderedList>
                        <ListItem>
                            Prelucrarea de date cu caracter personal înseamnă orice operaţiune sau set de operaţiuni care se efectuează asupra datelor cu caracter personal, prin mijloace automate sau neautomate, cum ar fi colectarea, înregistrarea, organizarea, stocarea, adaptarea ori modificarea, extragerea, consultarea, utilizarea, dezvăluirea către terţi prin transmitere, diseminare sau în orice alt mod, alăturarea ori combinarea, blocarea, ştergerea sau distrugerea, stocarea – păstrarea pe orice fel de suport a datelor cu caracter personal culese;
                        </ListItem>
                        <ListItem>
                            Scopurile prelucrării și persoanele vizate: Datele dvs. cu caracter personal vor fi colectate în scopuri determinate, explicite şi legitime și vor fi prelucrate pe principiul integrității și al confidențialităţii, într-un mod care asigură securitatea adecvată a datelor cu caracter personal, inclusiv protecţia împotriva prelucrării neautorizate sau ilegale şi împotriva pierderii, a distrugerii sau a deteriorării accidentale, prin luarea de măsuri tehnice sau organizatorice corespunzătoare.
                        </ListItem>
                        <Text>
                            Prelucrarea datelor cu caracter personal ale dvs. în calitate de persoană vizată se realizează fără a se limita în următoarele scopuri:
                        </Text>
                        <UnorderedList>
                            <ListItem>
                                Pentru furnizarea serviciilor hoteliere, organizare evenimente;
                            </ListItem>
                            <ListItem>
                                Pentru a fi contactat și a vă răspunde în legătură cu serviciile pe care le utilizați sau preconizați să le utilizați de la unităţile hoteliere din cadrul GRAND HOTEL CEAHLAU ;
                            </ListItem>
                            <ListItem>
                                În scopuri contabile, financiare privind facturarea;
                            </ListItem>
                            <ListItem>
                                Comunicări de marketing ;
                            </ListItem>
                            <ListItem>
                                Gestionarea sistemelor noastre de comunicaţii şi IT;
                            </ListItem>
                            <ListItem>
                                Pentru raportări la Institutul Naţional de Statistică, executori judecătoreşti, instituţii bancare etc.;
                            </ListItem>
                            <ListItem>
                                Pentru recuperarea creanțelor datorate, datele vor fi communicate apărătorilor aleşi, instanțelor de judecată în vederea formulării de acțiuni și reprezentării în instanță;
                            </ListItem>
                            <ListItem>
                                Pentru alte scopuri determinate de contract sau lege, ca obligație legală. Datele cu caracter personal pot viza următoarele persoane:
                                <List>
                                    <ListItem>
                                        <ListIcon as={ArrowForwardIcon} />
                                        clienți existenți sau potențiali, agenți, distribuitori, subcontractori, parteneri de afaceri (care sunt persoane fizice);
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={ArrowForwardIcon} />
                                        angajați sau persoane de contact ale potențialilor clienți, ai clienților, agenților, distribuitorilor, subprocesatorilor sau persoanelor împuternicite, parteneri de afaceri și vânzători (care sunt persoane fizice);
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={ArrowForwardIcon} />
                                        angajați, agenți, consilieri și persoane independente ale Clientului (care sunt persoane fizice) și / sau angajaţii GRAND HOTEL CEAHLAU .
                                    </ListItem>
                                </List>
                            </ListItem>
                        </UnorderedList>
                        <Text>
                            În cazul în care trebuie să prelucrăm date cu caracter personal obținute de la terți, persoane juridice (Agenţie de turism, Angajator, Organizatori de Evenimente), aceștia din urmă au obligația de a vă furniza informațiile necesare cu privire la utilizarea datelor cu caracter personal transmise şi să vă obţină consimţământul.
                        </Text>
                        <ListItem>
                            Principiile prelucrării: Prelucrăm datele cu caracter personal în condiţii de legalitate, echitate şi transparenţă.
                        </ListItem>
                        <ListItem>
                            Baza legală a prelucrării: Baza legală pentru colectarea şi prelucrarea datelor cu caracter personal (datele cu caracter anonim și agregate nu sunt considerate date cu caracter personal) pentru fiecare din scopurile menţionate în prezenta Informare este oricare din cele ce urmează:
                        </ListItem>
                        <Text>
                            Consimţământul persoanei vizate – acest consimțământ se va da la momentul completării fișei de înregistrare a sosirii/semnării contractului de prestări servicii hoteliere/semnării Anexei la fişa de poștă/semnării acordului sau a altor documente în care se menționează explicit acest lucru. Semnarea oricărui din acest document constituind acordarea consimţământului persoanei vizate cu privire la prelucrarea datelor sale personale.
                        </Text>
                        <Text>
                            Consimţământul pentru folosirea datelor cu caracter personal trebuie acordat printr-o acţiune neechivocă care să constituie o manifestare liber exprimată, specifică, în cunoştinţă de cauză şi clară a acordului persoanei vizate pentru prelucrarea datelor sale cu caracter personal. Consimţământul trebuie să vizeze toate activităţile de prelucrare efectuate în acelaşi scop sau în aceleaşi scopuri. Dacă prelucrarea datelor se face în mai multe scopuri, consimţământul ar trebui dat pentru toate scopurile prelucrării. În cazul în care consimţământul persoanei vizate trebuie acordat în urma unei cereri transmise pe cale electronică, cererea respectivă trebuie să fie clară şi concisă şi să nu perturbe în mod inutil utilizarea serviciului pentru care se acordă consimţământul.
                        </Text>
                        <UnorderedList>
                            <ListItem>
                                scop de prelucrare compatibil cu scopul inițial al prelucrării datelor cu caracter personal;
                            </ListItem>
                            <ListItem>
                                executarea contractului la care persoana vizată este parte sau cererea acestuia efectuată înainte de încheierea unui contract, în vederea încheierii contractului sau al executării contractului;
                            </ListItem>
                            <ListItem>
                                obligația legală: conformarea cu cerinţele legale imperativ aplicabile;
                            </ListItem>
                            <ListItem>
                                interesul legitim şi legal pentru a asigura furnizarea serviciilor proprii, pentru prelucrări în scopuri de marketing, publicitate, marketing direct, sondaje și comunicări prin e-mail sau alte canale, pentru prevenirea și combaterea fraudelor.
                            </ListItem>
                        </UnorderedList>
                        <ListItem>Obligațiile GRAND HOTEL CEAHLAU  privind prelucrarea datelor cu caracter personal:</ListItem>
                        <UnorderedList>
                            <ListItem>
                                GRAND HOTEL CEAHLAU  va procesa Datele cu caracter personal doar în scopul legal al prestării propriilor servicii, al îndeplinirii instrucţiunilor scrise primite din partea clientului sau a reprezentantului său legal menționat în Contract sau conform dispozițiilor legale aplicabile.
                            </ListItem>
                            <ListItem>
                                GRAND HOTEL CEAHLAU  va pune în aplicare măsurile tehnice și organizatorice adecvate pentru a asigura un nivel de securitate adecvat riscurilor prezentate de prelucrarea datelor cu caracter personal, în special în ceea ce privește protecția împotriva distrugerii, pierderii, alterării, dezvăluirii neautorizate sau accesului neautorizat la datele personale.
                            </ListItem>
                            <ListItem>
                                GRAND HOTEL CEAHLAU  va întreprinde măsuri rezonabile pentru a se asigura că numai personalul autorizat are acces la astfel de date cu caracter personal și că orice persoană pe care o autorizează să aibă acces la datele cu caracter personal este obligată să respecte confidențialitatea datelor prelucrate;
                            </ListItem>
                            <ListItem>
                                GRAND HOTEL CEAHLAU , fără întârzieri nejustificate, după ce a luat cunoștință de acest lucru, va notifica Clientul despre orice încălcare a securității care duce în mod accidental sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizată a datelor cu caracter personal transmise, stocate sau prelucrate într-un alt mod, sau la accesul neautorizat la acestea de către GRAND HOTEL CEAHLAU , persoanele împuternicite de acesta sau orice altă terță parte identificată sau neidentificată („Încălcarea securității datelor cu caracter personal”);
                            </ListItem>
                            <ListItem>
                                GRAND HOTEL CEAHLAU  va acorda Clientului asistenţă rezonabilă în legătură cu orice încălcare a securității datelor cu caracter personal aflate în posesia GRAND HOTEL CEAHLAU , în măsura în care afectează Clientul.
                            </ListItem>
                        </UnorderedList>
                        <ListItem>Termenele de păstrare a datelor: Termenele limită preconizate pentru păstrarea și ştergerea diferitelor categorii de date vor fi cele stabilite de legislația în vigoare inclusiv cea privind arhivarea, respectiv până la realizarea scopului pentru care au fost colectate, pe toată durata necesară folosirii acestora astfel cum este stabilit de legislația în vigoare (inclusiv cea fiscal</ListItem>
                        <ListItem>Subcontractori: Pentru furnizarea serviciilor, GRAND HOTEL CEAHLAU  poate angaja persoane împuternicite, operatori parte, furnizori de servicii în calitate de subcontractori. În aceste situaţii, GRAND HOTEL CEAHLAU  se va asigura că orice subcontractant pe care îl angajează a semnat un contract cu clauze contractuale standard care oferă garanţii suficiente pentru punerea în aplicare a unor măsuri tehnice şi organizatorice adecvate, astfel încât prelucrarea să respecte cerinţele prevăzute în GDPR şi să asigure protecţia drepturilor. GRAND HOTEL CEAHLAU  se va asigura, de asemenea, că subcontractorii prelucrează datele pe baza instrucțiunilor documentate și că aceștia vor respecta confidențialitatea datelor cu caracter personal prelucrate sau au o obligaţie statutară adecvată de confidenţialitate. </ListItem>
                        <ListItem>Drepturile persoanelor vizate: </ListItem>
                        <UnorderedList>
                            <ListItem>
                                Dreptul de informare și de acces la datele cu caracter personal. Persoana vizată are dreptul de a solicita o copie a datelor cu caracter personal deținute de GRAND HOTEL CEAHLAU . Cererea se adresează în scris la unităţile hoteliere sau pe e-mail la adresele : office@hotelceahlau.ro și/sau economic@hotelceahlau.ro
                            </ListItem>
                            <ListItem>
                                Dreptul de rectificare a datelor cu caracter personal. Persoana vizată are dreptul de a rectifica datele dacă acestea nu sunt corecte. Actualizarea datelor considerate incorecte se va face în baza unei cereri scrise adresate la sediul unităţilor hoteliere sau pe e-mail la adresa dpo@rinhotels.ro conform modelului afişat pe site.
                            </ListItem>
                            <ListItem>
                                Dreptul de ștergere. GRAND HOTEL CEAHLAU  va prelucra și păstra datele doar atât timp cât acest lucru este necesar. La expirarea perioadelor de timp menționate pentru păstrarea datelor, acestea vor fi șterse și anonimizate. În anumite circumstanțe, persoana vizată are dreptul însă de a solicita ștergerea datelor sale personale.
                            </ListItem>
                            <ListItem>
                                Dreptul de a restricționa utilizarea datelor cu caracter personal.
                            </ListItem>
                            <ListItem>
                                Dreptul de opoziție la utilizarea datelor cu caracter personal.
                            </ListItem>
                            <ListItem>
                                Dreptul la portabilitatea datelor.
                            </ListItem>
                            <ListItem>
                                Dreptul de retragere a consimţământului în orice moment. Retragerea consimţământului poate avea loc în orice moment și va produce efecte doar pentru viitor, prelucrarea efectuată anterior retragerii rămânând în continuare valabilă.
                            </ListItem>
                        </UnorderedList>
                        <Text>
                            Atunci când prelucrarea se bazează pe articolul 6 alineatul (1) litera (a) ”persoana vizată și-a dat consimțământul pentru prelucrarea datelor sale cu caracter personal pentru unul sau mai multe scopuri specifice” sau pe articolul 9 alineatul (2) litera (a) ”persoana vizată și-a dat consimțământul explicit pentru prelucrarea acestor date cu caracter personal pentru unul sau mai multe scopuri specifice, cu excepția cazului în care dreptul Uniunii sau dreptul intern prevede ca interdicția prevăzută la alineatul (1) să nu poată fi ridicată prin consimțământul persoanei vizate” din GDPR, aveți dreptul de a vă retrage consimțământul în orice moment, fără a afecta legalitatea prelucrării efectuate pe baza consimțământului înainte de retragerea acestuia. Astfel, puteți modifica sau elimina consimțământul în orice moment, și vom acționa imediat în consecință, cu excepția cazului în care există un motiv legal sau un interes legitim pentru a nu face acest lucru.
                        </Text>
                    </OrderedList>
                    <li>Durata pentru care vă prelucrăm datele</li>
                    <Text>
                        Ca principiu, proprietarul hotelceahlau.ro va prelucra datele dumneavoastră cu caracter personal atât cât este necesar pentru realizarea scopurilor de prelucrare menționate mai sus.
                    </Text>
                    <li>Dezvăluirea datelor cu caracter personal</li>
                    <Text>
                        Pentru îndeplinirea scopurilor de prelucrare, proprietarul hotelceahlau.ro poate dezvălui datele dumneavoastră către parteneri, către terțe persoane sau entități care sprijină hotelceahlau.ro în desfășurarea activității prin intermediul Site-ului (de exemplu firme de curierat, furnizori de servicii IT), ori către autoritățile publice centrale/locale, în următoarele cazuri exemplificativ enumerate:
                    </Text>
                    <UnorderedList>
                        <ListItem>
                            pentru administrarea Site-ului;
                        </ListItem>
                        <ListItem>
                            în situațiile în care această comunicare ar fi necesară pentru atribuirea de premii sau alte facilități persoanelor vizate, obținute ca urmare a participării lor la diverse campanii promoționale organizate de către hotelceahlau.ro prin intermediul Site-ului.(ACEASTA SECTIUNE ESTE IN LUCRU);
                        </ListItem>
                        <ListItem>
                            pentru menținerea, personalizarea și îmbunătățirea Site-ului și a serviciilor derulate prin intermediul lui;
                        </ListItem>
                        <ListItem>
                            pentru efectuarea analizei datelor, testarea și cercetarea, monitorizarea tendințelor de utilizare și activitate, dezvoltarea caracteristicilor de siguranță și autentificarea utilizatorilor;
                        </ListItem>
                        <ListItem>
                            pentru transmiterea de comunicări comerciale de marketing, în condițiile și limitele prevăzute de lege;
                        </ListItem>
                        <ListItem>
                            atunci când dezvăluirea datelor cu caracter personal este prevăzută de lege etc.
                        </ListItem>
                    </UnorderedList>
                    <li>Transferul datelor cu caracter personal</li>
                    <Text>Datele cu caracter personal furnizate către hotelceahlau.ro pot fi transferate în afara României, dar doar către state din Uniunea Europeană.</Text>
                    <li>Solicitări. Plângeri. </li>
                    <Text>
                        Orice solicitare în legătură cu oricare dintre drepturile dvs. sau plângere în legătură cu modul în care GRAND HOTEL CEAHLAU  prelucrează datele cu caracter personal se va adresa în scris, datată şi semnată  la Grand Hotel Ceahlau S.A. Piatra Neamț, Piața Ștefan cel Mare nr. 3 sau pe email la adresele : office@hotelceahlau.ro și/sau economic@hotelceahlau.ro .În cazul în care nu sunteți mulțumit(ă) de soluția primită puteți contacta Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal – cu sediul în: B-dul G-ral Gheorghe Magheru nr. 28-30, sector 1, București, cod poștal 0103336, e-mail: anspdcp@dataprotection.ro sau de a se adresa justiției. Precizăm faptul că exercitarea drepturilor este gratuită pentru o singură solicitare în cursul unui an.
                        Ne rezervăm dreptul de a ne modifica, atunci când considerăm oportun, practicile de protecţie a datelor și de actualiza și modifica prezenta notă de informare în orice moment. Din acest motiv, vă încurajăm să verificaţi periodic prezenta notă de informare.
                    </Text>
                </ol>
            </Box>
        </Layout>
    )
}
