import { useCallback, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Box } from "@chakra-ui/react";


export default function Terms({innerWidth}) {
    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);
    return (
        <Layout title={lang==='RO' ? 'Termeni și condiții' : 'Terms and conditions'}>
            <Box pt={innerWidth >= 900 ? 40 : 36} pb={8}>
                
            </Box>
        </Layout>
    )
}
