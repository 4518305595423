import { Box, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function Hamburger({open, color}) {
    const first = {
        open: {
            y:11,
            x:-1,
            rotate: -45,
            ease: "easeOut",
        },
        close: {
            rotate: 0,
            ease: "easeOut",
        }
    }
    const second = {
        open: {
            rotate: 45,
            ease: "easeOut",
        },
        close: {
            rotate: 0,
            ease: "easeOut",
        }
    }
    const third = {
        open: {
            y:-10,
            rotate:45,
            opacity:0,
            ease: "easeOut",
        },
        close: {
            rotate:0,
            opacity:1,
            ease: "easeOut",
        }
    }
    return (
        <VStack width="2.5rem" height="2.5rem" alignContent="center" justifyContent="center">
            <motion.div animate={open ? "open" : "close"} variants={first}><Box bg={color} width="2.25rem" height="0.2rem"></Box></motion.div>
            <motion.div animate={open ? "open" : "close"} variants={second}><Box bg={color} width="2.25rem" height="0.2rem"></Box></motion.div>
            <motion.div animate={open ? "open" : "close"} variants={third}><Box bg={color} width="2.25rem" height="0.2rem"></Box></motion.div>
        </VStack>
    )
}