import { useParams } from "react-router-dom"
import { Box, Button, Heading, HStack, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, StackDivider, Text, useDisclosure, VStack } from "@chakra-ui/react"
import Layout from "../components/Layout"
import { useCallback, useEffect, useState } from "react"
import Loading from "../components/Loading"
import { axiosInstance } from "../config"
import Error from "../components/Error"
import Checkout from "../components/Checkout"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import compareAsc from 'date-fns/compareAsc'
import { differenceInDays, set } from "date-fns"

export default function RezervationById({ innerWidth }) {
    const { bookingId } = useParams()
    const [loading, setLoading] = useState(false)
    const [booking, setBooking] = useState()
    const [roomSelection, setRoomSelection] = useState()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: cancelIsOpen, onOpen: cancelOnOpen, onClose: cancelOnClose } = useDisclosure()
    const { isOpen: refundIsOpen, onOpen: refundOnOpen, onClose: refundOnClose } = useDisclosure()
    const [amount, setAmount] = useState(0)
    const [lang, setLang] = useState(window.localStorage.lang)
    const [paymentIntentId, setPaymentIntentId] = useState('')
    const [password, setPassword] = useState()
    const [trigger, setTrigger] = useState(false)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const getData = async () => {
        try {
            setLoading(true)
            const data = (await axiosInstance.post('/bookings/getBooking', { bookingId: bookingId, password })).data
            const types = {
                single: 0,
                double: 0,
                kingSize: 0,
                apartment: 0
            }
            data.rooms.map(room => {
                types[room.type]++
            })
            setRoomSelection(types)
            setBooking(data)
            setLoading(false)
            setTrigger(true)
        } catch (err) {
            toast.error(err.response.data.err[lang.toLowerCase()])
            if (err.response.data.err[lang.toLowerCase()] === 'Password is incorrect' || err.response.data.err[lang.toLowerCase()] === 'Parola este incorectă')
                setTrigger(false)
            else setTrigger(true)
            setLoading(false)
        }
    }

    const getDate = (stringDate) => {
        const date = new Date(stringDate)
        return String(date.getDate()) + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    }

    const payErr = {
        ro: 'Termenul de plată a trecut! Vă rugăm să faceți altă rezervare',
        en: 'Past due date! Please make another booking'
    }

    const openModal = (amount, dueDate) => {
        const dateNow = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        }
        const dateDue = {
            year: new Date(dueDate).getFullYear(),
            month: new Date(dueDate).getMonth() + 1,
            day: new Date(dueDate).getDate()
        }
        const diff = compareAsc(new Date(dateDue.year, dateDue.month, dateDue.day), new Date(dateNow.year, dateNow.month, dateNow.day))
        if (diff < 0) {
            toast.error(payErr[lang.toLowerCase()])
        } else {
            setAmount(amount)
            onOpen()
        }
    }

    const refundErr = {
        ro: 'Termenul de restituire a banilor a trecut!',
        en: 'Refund date has passed!'
    }

    const refundModalOpen = (amount, checkIn) => {
        const dateNow = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        }
        const dateDue = {
            year: new Date(checkIn).getFullYear(),
            month: new Date(checkIn).getMonth() + 1,
            day: new Date(checkIn).getDate()
        }
        const diff = compareAsc(new Date(dateDue.year, dateDue.month, dateDue.day), new Date(dateNow.year, dateNow.month, dateNow.day))
        if (diff < 0) {
            toast.error(refundErr[lang.toLowerCase()])
        } else {
            const days = differenceInDays(new Date(dateDue.year, dateDue.month, dateDue.day), new Date(dateNow.year, dateNow.month, dateNow.day))
            if (days >= 5) setAmount(amount)
            else if (days >= 2) setAmount(amount * 0.5)
            else {
                toast.error(refundErr[lang.toLowerCase()])
                return;
            }
            refundOnOpen()
        }
    }

    const errMessage = {
        ro: 'A apărut o eroare! Încercați mai târziu',
        en: 'Something went wrong! Try again later'
    }

    const handleCancel = async (bookingId) => {
        const successMessage = {
            ro: 'Rezervarea a fost anulată',
            en: 'Booking canceled successfully'
        }
        try {
            setLoading(true)
            await axiosInstance.post('/bookings/cancelBooking', { bookingId, lang })
            cancelOnClose()
            toast.success(successMessage[lang.toLowerCase()])
            setLoading(false)
            window.location.href = '/rezervari'
        } catch (err) {
            setLoading(false)
            toast.error(errMessage[lang.toLowerCase()])
        }
    }

    const handleRefund = async (bookingId) => {
        const successMessage = {
            ro: 'Cererea a fost înregistrată',
            en: 'Request registered successfully'
        }
        try {
            setLoading(true)
            await axiosInstance.post('/payment/refundBooking', { bookingId, amount, lang })
            setLoading(false)
            refundOnClose()
            toast.success(successMessage[lang.toLowerCase()])
            window.location.reload()
        } catch (err) {
            setLoading(false)
            toast.error(errMessage[lang.toLowerCase()])
        }
    }

    const getPaymentId = (id) => {
        setPaymentIntentId(id)
    }

    return (
        <Layout title={lang === 'RO' ? "Rezervare" : 'Booking'}>
            <ToastContainer pauseOnHover theme="colored" style={{
                marginTop: '150px'
            }} />
            {
                loading ? (<Loading />) : (
                    !trigger ? (
                        <VStack mt={72} width="fit-content" ml='auto' mr='auto'>
                            <Heading fontSize={['5xl', , '6xl']} textAlign='center'>{lang === 'RO' ? 'Parola:' : 'Password:'}</Heading>
                            <Input size="lg" onChange={(e) => setPassword(e.target.value)} textAlign='center' fontSize={['3xl', , '3xl']} bg='white.cultured' type="number" color="black.dark" fontWeight="bold" borderStyle="solid" borderWidth={2} borderColor="accents.manhattan" required focusBorderColor="black.jon" />
                            <Button mt={6} fontSize={['lg', , '2xl']} onClick={getData} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Vezi Rezervarea' : 'See Booking'}</Button>
                        </VStack>
                    ) : (
                        !booking ? (
                            <Error />
                        ) : (
                            <VStack divider={<StackDivider borderColor='accents.raw-sienna' />} mt={72} mb={8} className="registration" width={['95%', , "70%"]} p={[4, , 50]} fontSize={innerWidth >= 1230 ? '2xl' : innerWidth >= 950 ? '2xl' : innerWidth >= 640 ? '2xl' : innerWidth >= 555 ? 'lg' : innerWidth >= 430 ? 'md' : innerWidth >= 385 ? 'xs' : 'xs'} marginLeft="auto" marginRight="auto">
                                <Heading fontSize={['5xl', , '6xl']}>{lang === 'RO' ? 'Rezervarea Ta' : 'Your Booking'}</Heading>
                                <Box pt={[2, , 8]}>
                                    <Text fontSize={['xl', , '3xl']}>{lang === 'RO' ? 'Nume' : 'Name'}: {booking.name}</Text>
                                    <Text fontSize={['xl', , '3xl']}>Email: {booking.email}</Text>
                                    <Text fontSize={['xl', , '3xl']}>{lang === 'RO' ? 'Telefon' : 'Phone Number'}: {booking.phoneNr}</Text>
                                    <Text fontSize={['xl', , '3xl']}>Check-in: {getDate(booking.fromDate)}</Text>
                                    <Text fontSize={['xl', , '3xl']}>Check-out: {getDate(booking.toDate)}</Text>
                                    <Text fontSize={['xl', , '3xl']}>{booking.totalDays} {lang === 'RO' ? 'nopți' : 'nights'} - {booking.pers} {lang === 'RO' ? 'persoane' : 'people'}</Text>
                                    <Text fontSize={['xl', , '3xl']} display={[roomSelection.single ? 'block' : 'none']}>{lang === 'RO' ? 'Camere Single' : 'Single Rooms'}: {roomSelection.single}</Text>
                                    <Text fontSize={['xl', , '3xl']} display={[roomSelection.double ? 'block' : 'none']}>{lang === 'RO' ? 'Camere Double' : 'Double Rooms'}: {roomSelection.double}</Text>
                                    <Text fontSize={['xl', , '3xl']} display={[roomSelection.kingSize ? 'block' : 'none']}>{lang === 'RO' ? 'Camere King Size' : 'King Size Rooms'}: {roomSelection.kingSize}</Text>
                                    <Text fontSize={['xl', , '3xl']} display={[roomSelection.apartment ? 'block' : 'none']}>{lang === 'RO' ? 'Apartamente' : 'Apartment'}: {roomSelection.apartment}</Text>
                                    <Text fontSize={['xl', , '3xl']}>{lang === 'RO' ? 'Preț total' : 'Total price'}: {booking.totalAmount} LEI</Text>
                                    <Text fontSize={['xl', , '3xl']}>
                                        {
                                            lang === 'RO' ? 'Termen de plată: ' : 'Payment due: '
                                        }
                                        {getDate(booking.paymentBy)}
                                    </Text>
                                    <Text fontSize={['xl', , '3xl']}>
                                        Status: {" "}
                                        {
                                            lang === 'RO' ? (
                                                booking.status === 'pending' ? 'neplătită' :
                                                    booking.status === 'payed' ? 'plătită' :
                                                        booking.status === 'refunded' ? 'banii au fost returnați' :
                                                            'anulată'
                                            ) : (
                                                booking.status === 'pending' ? 'unpayed' :
                                                    booking.status === 'payed' ? 'payed' :
                                                        booking.status === 'refunded' ? 'refunded' :
                                                            'canceled'
                                            )
                                        }
                                    </Text>
                                    <Stack direction={['column', , 'row']} pt={3}>
                                        <Button fontSize={['lg', , '2xl']} display={booking.status === 'pending' ? 'flex' : 'none'} onClick={() => openModal(booking.totalAmount, booking.paymentBy)} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Plătește tot' : 'Full payment'}</Button>
                                        <Button fontSize={['lg', , '2xl']} display={booking.status === 'pending' ? 'flex' : 'none'} disabled={booking.status === 'pending' ? false : true} onClick={cancelOnOpen} p={4} bg="red.500" color="black.dark" borderColor="black.dark" borderWidth={2}>{lang === 'RO' ? 'Anulare' : 'Cancel'}</Button>
                                    </Stack>
                                    <Button pt={4} fontSize={['md', , 'xl']} display={booking.status === 'payed' ? 'flex' : 'none'} onClick={() => refundModalOpen(booking.totalAmount, booking.fromDate)} p={4} className="btn-group" bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Restituirea banilor' : 'Refund'}</Button>
                                    <Modal isOpen={isOpen} onClose={onClose} size="lg">
                                        <ModalOverlay />
                                        <ModalContent>
                                            <ModalCloseButton onClick={onClose} />
                                            <ModalBody m={6}>
                                                <Checkout amount={amount} bookingId={bookingId} getPaymentId={getPaymentId} lang={lang} />
                                            </ModalBody>
                                        </ModalContent>
                                    </Modal>
                                    <Modal isOpen={cancelIsOpen} size='lg'>
                                        <ModalOverlay />
                                        <ModalContent bg="red.300">
                                            <ModalHeader textAlign="center" fontSize={['3xl', , '4xl']}>{lang === 'RO' ? 'Ești sigur că vrei să anulezi rezervarea' : 'Are you sure you want to cancel your booking'}?</ModalHeader>
                                            <ModalBody>
                                                <HStack justifyContent="center" spacing={12}>
                                                    <Button fontSize={['lg', , '2xl']} bg="red" onClick={() => handleCancel(bookingId)}>{lang === 'RO' ? 'Anulare Rezervare' : 'Cancel Booking'}</Button>
                                                    <Button fontSize={['lg', , '2xl']} onClick={cancelOnClose}>{lang === 'RO' ? 'Nu' : 'No'}</Button>
                                                </HStack>
                                            </ModalBody>
                                        </ModalContent>
                                    </Modal>
                                    <Modal isOpen={refundIsOpen} onClose={refundOnClose} size='lg'>
                                        <ModalOverlay />
                                        <ModalContent>
                                            <ModalHeader textAlign='center' fontSize={['3xl', , '4xl']}>{lang === 'RO' ? 'Restituirea banilor' : 'Refund'}</ModalHeader>
                                            <ModalBody>
                                                <VStack>
                                                    <Text textAlign='center' fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Suma de' : 'The'} {amount} RON {lang === 'RO' ? 'va fi restituită în contul dvs în 5-10 zile.' : 'will be refunded in your account in 5-10 days.'}</Text>
                                                    <HStack justifyContent='center' spacing={12}>
                                                        <Button fontSize={['lg', , '2xl']} onClick={() => handleRefund(bookingId)} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Continuă' : 'Refund'}</Button>
                                                        <Button fontSize={['lg', , '2xl']} borderStyle='solid' borderColor='black.dark' borderWidth={2} bg='accents.raw-sienna' color='white.cultured' onClick={refundOnClose}>{lang === 'RO' ? 'Anulare' : 'Cancel'}</Button>
                                                    </HStack>
                                                </VStack>
                                            </ModalBody>
                                        </ModalContent>
                                    </Modal>
                                </Box>
                            </VStack>
                        )
                    )
                )
            }
        </Layout>
    )
}