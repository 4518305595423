import Layout from "../components/Layout";
import Error from "../components/Error";
import { useCallback, useEffect, useState } from "react";

export default function Error404() {
    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    return (
        <Layout title={lang==='RO' ? "Pagină Inexistentă" : 'Error 404'}>
            <Error />
        </Layout>
    )
}