import { useState } from "react";
import { useStripe, useElements, AddressElement } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Box } from "@chakra-ui/react";
import { axiosInstance } from "../config";

export default function CheckoutForm({ bookingId, amount, lang }) {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: 'if_required'
    })
    if (error) {
      setMessage(error.message)
    }
    if (!error) {
      await axiosInstance.post('/payment/confirmPayment', { bookingId, amount, paymentId: elements._commonOptions.clientSecret.id, lang })
        .then(() => {
          window.location = `${window.location.origin}/completion`
        })
    }
    setIsProcessing(false)
  };

  return (
    <Box>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement />
        <AddressElement options={{ mode: 'billing' }} />
        <button disabled={isProcessing} id="submit" className="btn-group" style={{
          padding: '4px',
          marginTop: '10px',
          backgroundColor: "#494041",
          color: "#F7F7F7",
          borderColor: "#D5873F",
          borderWidth: 2,
          borderRadius: '5px',
          fontSize: '1.5rem'
        }}>
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </Box>
  );
}
