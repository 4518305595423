import { Box, Button, StackDivider, VStack, Icon, Center } from "@chakra-ui/react";
import { removeCookie } from "../utils/authHelpers";
import Hamburger from "./Hamburger"
import { RiBookmark3Fill } from "react-icons/ri"
import { BsFillPersonFill } from "react-icons/bs"
import { ImExit } from "react-icons/im"
import { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom"

function SideNav({ innerWidth }) {
    const [open, setOpen] = useState(false)
    const [lang, setLang] = useState(window.localStorage.lang)
    const sidebarAnim = {
        open: {
            x: 0,
            display: 'flex',
            transition: {
                duration: 1
            }
        },
        close: {
            display: 'none',
            transition: {
                duration: 1
            }
        }
    }

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const logout = () => {
        removeCookie('token')
        window.location.href = "/"
    }
    const handleClickHamburger = () => {
        if (innerWidth <= 400) {
            setOpen(false)
        }
        else{
            setOpen(!open)
        }
    }
    return (
        <Box position="fixed" zIndex={2} borderTopWidth={2} borderRightWidth={2} borderColor="accents.raw-sienna" mt={40} borderRadius="0 8px 8px 0">
            <motion.div animate={!open ? 'open' : 'close'} variants={sidebarAnim}>
                <VStack id="sidebar" width={["6vh",, '10vh']} bg="grey.link-water" pt={8} borderRadius="0 8px 8px 0" divider={<StackDivider borderColor="accents.raw-sienna" borderWidth={2} />}>
                    <Button variant="ghost" onClick={handleClickHamburger}>
                        <Hamburger color="black.dark" open={open} />
                    </Button>
                    <Link to='/cont'><Icon fontSize={["2.5vh",, '3vh']} mt={4} mb={4} cursor="pointer" as={BsFillPersonFill} /></Link>
                    <Link to='/cont/rezervari'><Icon fontSize={["2.5vh",, '3vh']} mt={4} mb={4} cursor="pointer" as={RiBookmark3Fill} /></Link>
                    <Icon fontSize={["2.5vh",, '3vh']} mt={4} mb={4} pl={1} cursor="pointer" as={ImExit} onClick={logout} />
                </VStack>
            </motion.div>

            <motion.div animate={open ? 'open' : 'close'} variants={sidebarAnim}>
                <VStack id="sidebar" bg="grey.link-water" pt={8} borderRadius="0 8px 8px 0" divider={<StackDivider borderColor="accents.raw-sienna" borderWidth={2} />}>
                    <Button variant="ghost" onClick={handleClickHamburger} mr="auto" ml={3}>
                        <Hamburger color="black.dark" open={open} />
                    </Button>
                    <Button fontSize='2xl' variant="ghost" width="20vh" mt={4} mb={4}><Icon fontSize={["2.5vh",, '3vh']} mt={4} mb={4} cursor="pointer" as={BsFillPersonFill} /><Link to='/cont'>{lang==='RO' ? 'Date cont' : 'Account Info'}</Link></Button>
                    <Button fontSize='2xl' variant="ghost" width="20vh" mt={4} mb={4}><Icon fontSize={["2.5vh",, '3vh']} mt={4} mb={4} cursor="pointer" as={RiBookmark3Fill} /><Link to='/cont/rezervari'>{lang==='RO' ? 'Rezervări' : 'Bookings'}</Link></Button>
                    <Button fontSize='2xl' variant="ghost" width="20vh" mt={4} mb={4} onClick={logout}><Icon fontSize={["2.5vh",, '3vh']} mt={4} mb={4} cursor="pointer" as={ImExit} onClick={logout} />{lang==='RO' ? 'Deconectare' : 'Logout'}</Button>
                </VStack>
            </motion.div>
        </Box>
    )
}

export default function Sidebar({ innerWidth, children }) {
    return (
        <Box>
            <SideNav innerWidth={innerWidth} />
            <Center position='relative' zIndex='0' as="main" h="full" ml={20}>
                {children}
            </Center>
        </Box>
    )
}