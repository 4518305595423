import { useCallback, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { axiosInstance } from "../config"
import { Box, Button, Grid, Heading, HStack, Icon, Input, InputGroup, InputLeftElement, Stack, Text, VStack } from "@chakra-ui/react";
import DatePicker from "react-datepicker"
import Loading from "../components/Loading"
import { BsFillPersonFill } from "react-icons/bs"
import { MdEmail, MdLocalPhone } from "react-icons/md"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import getNrOfRooms from "../utils/nrOfRooms";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { getCookie, isAuth } from "../utils/authHelpers";
import { compareAsc } from "date-fns/esm";
import { sub } from "date-fns";

export default function Rezervari({ innerWidth }) {
    const [rooms, setRooms] = useState([])
    const [loading, setLoading] = useState(true)
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [pers, setPers] = useState(1)
    const [verified, setVerified] = useState(false)
    const [roomCombinations, setRoomCombinations] = useState()
    const [selectedRooms, setSelectedRooms] = useState({
        single: 0,
        double: 0,
        kingSize: 0,
        apartment: 0
    })
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [paymentBy, setPaymentBy] = useState('')
    const [lang, setLang] = useState(window.localStorage.lang)
    const [booked, setBooked] = useState(false)
    const [errorState, setErrorState] = useState({
        name: false,
        email: false,
        phoneNr: false
    })

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true)
                if (isAuth()) {
                    if (window) {
                        const token = getCookie('token')
                        try {
                            setLoading(true)
                            const data = (await axiosInstance.post('/users/getUser', { token })).data
                            setName(data.user.name)
                            setEmail(data.user.email)
                            setLoading(false)
                        } catch (err) {
                            setLoading(false)
                            window.location.href = '/'
                        }
                    }
                }
                setLoading(false)
            } catch (err) {
                setLoading(false)
            }
        }
        getData()
    }, [])

    const filterByDate = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post('/rooms/getFreeRooms', { fromDate, toDate })
            if (response.status !== 200) {
                toast.error(lang === 'RO' ? 'Perioada aleasă este invalidă' : 'Invalid dates')
                setLoading(false)
                return
            }
            if (response.data.rooms.length === 0) {
                toast.error(lang === 'RO' ? 'Nu sunt camere disponibile în perioada aleasă' : 'No rooms available in the selected period')
                setLoading(false)
                return
            }
            setRooms(response.data.rooms)
            setRoomCombinations(getNrOfRooms(pers))
            setVerified(true)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(lang === 'RO' ? 'Alegeți perioada' : 'Choose dates')
        }
    }

    const getTotalDays = (toDate, fromDate) => {
        let diff = toDate.getTime() - fromDate.getTime()
        let days = Math.ceil(diff / (1000 * 3600 * 24))
        return days
    }
    const getDate = (date) => {
        return String(date.getDate()) + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    }
    const onChangeDates = (dates) => {
        const [start, end] = dates
        setFromDate(start)
        setToDate(end)
        setVerified(false)
        const date = new Date()
        if (start) {
            const dueDate = sub(new Date(start), { days: 14 })
            if (compareAsc(new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()), new Date(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate())) <= 0)
                setPaymentBy(dueDate)
            else
                setPaymentBy(date)
        }
        else
            setPaymentBy('')
        setSelectedRooms({ ...selectedRooms, single: 0, double: 0, kingSize: 0, apartment: 0 })
    }
    const handlePers = (value) => {
        setPers(value)
        setVerified(false)
        setSelectedRooms({ ...selectedRooms, single: 0, double: 0, kingSize: 0, apartment: 0 })
    }
    const handleSelectedRooms = (type, nr) => {
        setSelectedRooms({ ...selectedRooms, [type]: nr })
    }

    const compareArr = (arr1, arr2) => {
        return JSON.stringify(arr1) === JSON.stringify(arr2)
    }

    const getNrOfSelectedRooms = () => {
        let nr = 0
        Object.keys(selectedRooms).forEach(key => {
            nr += selectedRooms[key]
        })
        return nr
    }

    const success = {
        ro: 'Camerele au fost rezervate',
        en: 'Booking successfull'
    }

    const fieldErr = {
        ro: 'Câmpurile sunt obligatorii',
        en: 'All fields are required'
    }

    const failed = {
        ro: 'Nu sunt suficiente camere disponibile',
        en: 'Not enough rooms avalible'
    }

    const roomErr = {
        ro: 'Numărul de camere selectat nu este bun',
        en: 'Number of rooms selected is not good'
    }

    const handleRezervation = async () => {
        if (!name) {
            setErrorState({ ...errorState, name: true })
            toast.error(fieldErr[lang.toLowerCase()])
        }
        else if (!email) {
            setErrorState({ ...errorState, email: true })
            toast.error(fieldErr[lang.toLowerCase()])
        }
        else if (!phone) {
            setErrorState({ ...errorState, phoneNr: true })
            toast.error(fieldErr[lang.toLowerCase()])
        }
        else {
            let combVerif = 0
            roomCombinations.map(comb => {
                if (compareArr(comb, Array(undefined, selectedRooms.single, selectedRooms.double, selectedRooms.kingSize, selectedRooms.apartment)))
                    combVerif = 1
            })
            if (combVerif) {
                const tempRooms = []
                let single = selectedRooms.single
                let double = selectedRooms.double
                let kingSize = selectedRooms.kingSize
                let apartment = selectedRooms.apartment
                let price = 0
                let totalDays = getTotalDays(toDate, fromDate)
                for (const room of rooms) {
                    if (single + double + kingSize + apartment === 0) break;
                    if (room.type === 'single' && single > 0) {
                        tempRooms.push(room)
                        price += (room.rentPerDay * totalDays + 2)
                        single--;
                    }
                    if (room.type === 'double' && double > 0) {
                        tempRooms.push(room)
                        price += (room.rentPerDay * totalDays + 2)
                        double--;
                    }
                    if (room.type === 'kingSize' && kingSize > 0) {
                        tempRooms.push(room)
                        price += (room.rentPerDay * totalDays + 2)
                        kingSize--;
                    }
                    if (room.type === 'apartment' && apartment > 0) {
                        tempRooms.push(room)
                        price += (room.rentPerDay * totalDays + 2)
                        apartment--;
                    }
                }
                if (tempRooms.length > 0 && single === 0 && double === 0 && kingSize === 0 && apartment === 0) {
                    const bookingDetails = {
                        rooms: tempRooms,
                        name: name,
                        email: email,
                        phoneNr: phone,
                        fromDate: new Date(fromDate),
                        toDate: new Date(toDate),
                        totalDays,
                        totalAmount: price,
                        paymentBy,
                        pers,
                        lang
                    }
                    try {
                        setLoading(true)
                        const result = await axiosInstance.post('/bookings/bookRoom', bookingDetails)
                        setLoading(false)
                        setBooked(true)
                        toast.success(success[lang.toLowerCase()])
                    } catch (err) {
                        toast.error(err.response.data.error.ro)
                        setLoading(false)
                    }
                } else {
                    toast.error(failed[lang.toLowerCase()])
                }
            } else {
                toast.error(roomErr[lang.toLowerCase()])
            }
            setVerified(false)
        }
    }
    return (
        <Layout title={lang === 'RO' ? "Rezervări" : 'Booking'}>
            <ToastContainer pauseOnHover theme="colored" style={{
                marginTop: '150px'
            }} />
            <VStack pt={72} pb={8}>
                {
                    loading ? <Loading /> : (

                        <VStack p={6} className="registration" textAlign="center" gap={4} width={['100%', , "60%"]}>
                            <Heading fontSize={['5xl', , '6xl']}>{lang === 'RO' ? 'Rezervări Online' : 'Online Booking'}</Heading>
                            <Stack direction={['column', , 'row']} justifyContent="center" fontSize={['lg', , '2xl']}>
                                <DatePicker placeholderText="Check-In - Check-Out" className="datePicker" dateFormat="dd/MM/yyyy" selected={fromDate} onChange={onChangeDates} startDate={fromDate} endDate={toDate} minDate={new Date()} selectsRange isClearable />
                            </Stack>
                            <Text fontSize={['lg', , '2xl']} id="paymentBy">
                                {
                                    paymentBy !== '' && (
                                        lang === 'RO' ?
                                            `Plata trebuie efectuata pana la data de ${getDate(paymentBy)}` :
                                            `Payment must be done by ${getDate(paymentBy)}`
                                    )
                                }
                            </Text>
                            <Stack direction={['column', , 'row']} justifyContent="center" gap={3}>
                                <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Numărul de persoane' : 'Number of people'}:</Text>
                                <HStack fontSize={['lg', , '2xl']}>
                                    <Button onClick={() => pers !== 1 ? handlePers(pers - 1) : ''} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={MinusIcon} /></Button>
                                    <Text pl={5} pr={5} pt={1} pb={1} textAlign='center' alignSelf='center' bg="white.cultured" color="black.dark" borderStyle="solid" borderWidth={2} borderColor="black.jon" borderRadius={5}>{pers}</Text>
                                    <Button onClick={() => handlePers(pers + 1)} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={AddIcon} /></Button>
                                </HStack>
                            </Stack>
                            <Button fontSize={['lg', , '2xl']} p={5} onClick={filterByDate} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Verifică disponibilitatea' : 'Check Avalability'}</Button>
                            {
                                verified && (
                                    <Box>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                                            <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Camere Single' : 'Single Rooms'}:</Text>
                                            <HStack>
                                                <Button onClick={() => selectedRooms.single !== 0 ? handleSelectedRooms('single', selectedRooms.single - 1) : ''} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={MinusIcon} /></Button>
                                                <Text fontSize={['lg', , '2xl']} pl={5} pr={5} pt={1} pb={1} bg="white.cultured" color="black.dark" borderStyle="solid" borderWidth={2} borderColor="black.jon" borderRadius={5}>{selectedRooms.single}</Text>
                                                <Button onClick={() => handleSelectedRooms('single', selectedRooms.single + 1)} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={AddIcon} /></Button>
                                            </HStack>

                                            <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Camere Double' : 'Double Rooms'}:</Text>
                                            <HStack>
                                                <Button onClick={() => selectedRooms.double !== 0 ? handleSelectedRooms('double', selectedRooms.double - 1) : ''} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={MinusIcon} /></Button>
                                                <Text fontSize={['lg', , '2xl']} pl={5} pr={5} pt={1} pb={1} bg="white.cultured" color="black.dark" borderStyle="solid" borderWidth={2} borderColor="black.jon" borderRadius={5}>{selectedRooms.double}</Text>
                                                <Button onClick={() => handleSelectedRooms('double', selectedRooms.double + 1)} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={AddIcon} /></Button>
                                            </HStack>

                                            <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Camere King Size' : 'King Size Rooms'}:</Text>
                                            <HStack>
                                                <Button onClick={() => selectedRooms.kingSize !== 0 ? handleSelectedRooms('kingSize', selectedRooms.kingSize - 1) : ''} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={MinusIcon} /></Button>
                                                <Text fontSize={['lg', , '2xl']} pl={5} pr={5} pt={1} pb={1} bg="white.cultured" color="black.dark" borderStyle="solid" borderWidth={2} borderColor="black.jon" borderRadius={5}>{selectedRooms.kingSize}</Text>
                                                <Button onClick={() => handleSelectedRooms('kingSize', selectedRooms.kingSize + 1)} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={AddIcon} /></Button>
                                            </HStack>

                                            <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Apartamente' : 'Apartments'}:</Text>
                                            <HStack>
                                                <Button onClick={() => selectedRooms.apartment !== 0 ? handleSelectedRooms('apartment', selectedRooms.apartment - 1) : ''} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={MinusIcon} /></Button>
                                                <Text fontSize={['lg', , '2xl']} pl={5} pr={5} pt={1} pb={1} bg="white.cultured" color="black.dark" borderStyle="solid" borderWidth={2} borderColor="black.jon" borderRadius={5}>{selectedRooms.apartment}</Text>
                                                <Button onClick={() => handleSelectedRooms('apartment', selectedRooms.apartment + 1)} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Icon fontSize={['sm', , 'md']} as={AddIcon} /></Button>
                                            </HStack>
                                        </Grid>
                                        <Heading fontSize={['md', , 'xl']} mt={8} textAlign="center">{selectedRooms.single} Single x 170 + {selectedRooms.double} Double x 280 + {selectedRooms.kingSize} King Size x 200 + {selectedRooms.apartment} Apartment x 400 + {getNrOfSelectedRooms()} {lang === 'RO' ? 'Camere' : 'Rooms'} x 2 = {selectedRooms.single * 170 + selectedRooms.double * 280 + selectedRooms.kingSize * 200 + selectedRooms.apartment * 400 + getNrOfSelectedRooms() * 2} RON / {lang === 'RO' ? 'noapte' : 'night'}</Heading>
                                    </Box>
                                )
                            }
                            {
                                verified && (
                                    <VStack width={["100%", , "60%"]} gap={2} fontSize={['lg', , '2xl']}>
                                        <InputGroup>
                                            <InputLeftElement color="black.jon" borderRadius={5}><BsFillPersonFill fontSize="3vh" /></InputLeftElement>
                                            <Input isInvalid={errorState.name} fontSize={['lg', , '2xl']} fontWeight="bold" placeholder={lang === 'RO' ? "Numele" : 'Name'} value={name} onChange={(e) => setName(e.target.value)} _placeholder={{ paddingLeft: '1px', fontSize: ['xs', , 'lg'] }} borderStyle="solid" borderWidth={2} focusBorderColor="accents.manhattan" />
                                        </InputGroup>
                                        <InputGroup>
                                            <InputLeftElement color="black.jon" borderRadius={5}><MdEmail fontSize="3vh" /></InputLeftElement>
                                            <Input isInvalid={errorState.email} fontSize={['lg', , '2xl']} fontWeight="bold" placeholder={lang === 'RO' ? "Adresa de email" : 'Email'} value={email} onChange={(e) => setEmail(e.target.value)} _placeholder={{ paddingLeft: '1px', fontSize: ['xs', , 'lg'] }} borderStyle="solid" borderWidth={2} focusBorderColor="accents.manhattan" />
                                        </InputGroup>
                                        <InputGroup>
                                            <InputLeftElement color="black.jon" borderRadius={5}><MdLocalPhone fontSize="3vh" /></InputLeftElement>
                                            <Input isInvalid={errorState.phoneNr} fontSize={['lg', , '2xl']} fontWeight="bold" placeholder={lang === 'RO' ? "Numărul de telefon" : 'Phone number'} onChange={(e) => setPhone(e.target.value)} _placeholder={{ paddingLeft: '1px', fontSize: ['xs', , 'lg'] }} borderStyle="solid" borderWidth={2} focusBorderColor="accents.manhattan" />
                                        </InputGroup>
                                    </VStack>
                                )
                            }
                            {
                                verified && (
                                    <>
                                        <Text fontSize={['4xl',, '5xl']}>{lang==='RO' ? 'În lucru': 'Work in progress'}</Text>
                                        <Button isDisabled={/*booked*/ true} fontSize={['lg', , '2xl']} onClick={handleRezervation} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Rezervă' : 'Book'}</Button>
                                    </>
                                )
                            }
                        </VStack>
                    )
                }
            </VStack>
        </Layout >
    )
}