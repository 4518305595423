import cookie from "js-cookie"

function setCookie(key, value) {
    if (window !== undefined) {
        cookie.set(key, value, { expires:1, secure: true })
    }
}

function removeCookie(key) {
    if (window !== undefined) {
        cookie.remove(key)
    }
}

function getCookie(key) {
    if (window !== undefined) {
        return cookie.get(key)
    }
}

function isAuth() {
    if (window !== undefined) {
        const cookie = getCookie('token')
        if (cookie) return true
        else return false
    }
}

export { setCookie, removeCookie, getCookie, isAuth }