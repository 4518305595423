import { Box, HStack, VStack, Button, Drawer, useDisclosure, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody, Image, Icon, DrawerHeader } from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Hamburger from "./Hamburger"
import { motion } from "framer-motion"
import { ImHome } from "react-icons/im"
import { FaGlassCheers } from "react-icons/fa"
import { FaBed } from "react-icons/fa"
import { RiBookmark3Fill } from "react-icons/ri"
import { BsFillPersonFill } from "react-icons/bs"
import { HiPhone } from "react-icons/hi"
import { isAuth, removeCookie } from "../utils/authHelpers"
import Flag from "react-world-flags"

function Navbar({ title }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const [state, setState] = useState('initial')
    const [open, setOpen] = useState(false)
    const [y, setY] = useState(window.scrollY)
    const [dropOpen, setDropOpen] = useState(false)
    const [authDropOpen, setAuthDropOpen] = useState(false)
    const [innerWidth, setInnerWidth] = useState(getWidth())
    const [lang, setLang] = useState('RO')

    function getWidth() {
        const { innerWidth: width } = window
        return width
    }

    const handleNavigation = useCallback((e) => {
        const window = e.currentTarget;
        if ((state === 'initial' || state === 'disappear') && y >= 80) {
            setDropOpen(false)
            setAuthDropOpen(false)
        }
        setY(window.scrollY);
    }, [y, state])

    useEffect(() => {
        function handleResize() {
            setInnerWidth(getWidth())
        }
        const lang = localStorage.getItem('lang')
        if (lang) setLang(lang)
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
            window.removeEventListener('resize', handleResize);
        };
    }, [handleNavigation]);


    const handleClickHamburger = () => {
        let prop
        if (state === "initial" || state === "disappear") prop = "appear"
        else prop = "disappear"
        setState(prop)
        if (prop === 'appear') setOpen(true)
        else {
            setOpen(false)
            setDropOpen(false)
            setAuthDropOpen(false)
        }
    }

    const logout = () => {
        removeCookie('token')
        window.location.href = '/'
    }

    const handleLang = () => {
        if (lang === 'RO') {
            setLang('EN')
            localStorage.setItem('lang', 'EN')
        }
        else {
            setLang('RO')
            localStorage.setItem('lang', 'RO')
        }
    }

    const homeAnim = {
        open: {
            opacity: 1,
            y: [200, 0],
            transition: {
                duration: 0.5
            }
        },
        close: {
            opacity: 0
        }
    }
    const camereAnim = {
        open: {
            opacity: 1,
            y: [200, 0],
            transition: {
                delay: 0.2,
                duration: 0.5
            }
        },
        close: {
            opacity: 0
        }
    }
    const evenimenteAnim = {
        open: {
            opacity: 1,
            y: [200, 0],
            transition: {
                delay: 0.4,
                duration: 0.5
            }
        },
        close: {
            opacity: 0
        }
    }
    const contactAnim = {
        open: {
            opacity: 1,
            y: [200, 0],
            transition: {
                delay: 0.6,
                duration: 0.5
            }
        },
        close: {
            opacity: 0
        }
    }
    const bookAnim = {
        open: {
            opacity: 1,
            y: [200, 0],
            transition: {
                delay: 0.8,
                duration: 0.5
            }
        },
        close: {
            opacity: 0
        }
    }
    const accountAnim = {
        open: {
            opacity: 1,
            y: [200, 0],
            transition: {
                delay: 1,
                duration: 0.5
            }
        },
        close: {
            opacity: 0
        }
    }
    const logoAnim = {
        show: {
            opacity: 1,
            display: 'block',
            transition: {
                duration: 1
            }
        },
        hide: {
            opacity: [1, 0],
            display: 'none',
            transition: {
                duration: 1
            }
        }
    }
    return (
        <Box>
            <HStack display={innerWidth >= 1070 ? 'flex' : 'none'} position="fixed" zIndex={99} top={0} justifyContent="space-between" className={`nav-bar ${y <= 80 ? 'top' : 'scroll'}`} p={4} color="white.cultured">
                <HStack position="absolute" top={0} left={0}>
                    <motion.div animate={y <= 80 ? 'show' : 'hide'} variants={logoAnim}>
                        <Image className='logo' onClick={() => window.location.href = '/'} src='/images/logo-alb.svg' width="38vh" height="17vh" />
                    </motion.div>
                    <motion.div animate={y <= 80 ? 'hide' : 'show'} variants={logoAnim}>
                        <HStack position="absolute" left={10} top={1} gap={4}>
                            <Image onClick={() => window.location.href = '/'} src='/images/logo-mobile.png' width="10vh" height="8vh" />
                            <Button className="hamburger-btn" variant="ghost" onClick={handleClickHamburger}>
                                <Hamburger open={open} color="white.cultured" />
                            </Button>
                        </HStack>
                    </motion.div>
                </HStack>
                <Box />
                <HStack>
                    <HStack>
                        {
                            title === "Grand Hotel Ceahlău" ?
                                <Button fontSize="2xl" className={y <= 80 ? 'appear selected' : `selected ${state}`} variant="ghost"><Link className="btn-link" to="/">{lang === 'RO' ? 'Acasă' : 'Home'}</Link></Button> :
                                <Button fontSize="2xl" className={y <= 80 ? 'appear nav-btn' : `nav-btn ${state}`} variant="ghost"><Link className="btn-link" to="/">{lang === 'RO' ? 'Acasă' : 'Home'}</Link></Button>
                        }
                        {
                            title === "Camere" || title === "Rooms" || title === "Single" || title === "Double" || title === "King Size" || title === "Apartment" ? (
                                <Box position="relative">
                                    <Button onClick={() => setDropOpen(!dropOpen)} fontSize="2xl" className={y <= 80 ? 'appear selected' : `selected ${state}`} variant="ghost">{lang === 'RO' ? 'Camere' : 'Rooms'}</Button>
                                    <VStack position="absolute" className={dropOpen ? 'appear' : 'disappear'} top={y <= 80 ? 10 : 14} bg="black.dark" p={2} borderRadius="0 0 10px 10px">
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere">{lang === 'RO' ? 'Toate Camerele' : 'All Rooms'}</Link></Button>
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere/single">Single</Link></Button>
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere/double">Double</Link></Button>
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere/king-size">King Size</Link></Button>
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere/apartment">Apartment</Link></Button>
                                    </VStack>
                                </Box>
                            ) : (
                                <Box position="relative">
                                    <Button onClick={() => setDropOpen(!dropOpen)} fontSize="2xl" className={y <= 80 ? 'appear nav-btn' : `nav-btn ${state}`} variant="ghost">{lang === 'RO' ? 'Camere' : 'Rooms'}</Button>
                                    <VStack position="absolute" className={dropOpen ? 'appear' : 'disappear'} top={y <= 80 ? 10 : 14} bg="black.dark" p={2} borderRadius="0 0 10px 10px">
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere">{lang === 'RO' ? 'Toate Camerele' : 'All Rooms'}</Link></Button>
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere/single">Single</Link></Button>
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere/double">Double</Link></Button>
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere/king-size">King Size</Link></Button>
                                        <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/camere/apartment">Apartment</Link></Button>
                                    </VStack>
                                </Box>
                            )
                        }
                        {
                            title === "Evenimente" || title === 'Events' ?
                                <Button fontSize="2xl" className={y <= 80 ? 'appear selected' : `selected ${state}`} variant="ghost"><Link className="btn-link" to="/evenimente">{lang === 'RO' ? 'Evenimente' : 'Events'}</Link></Button> :
                                <Button fontSize="2xl" className={y <= 80 ? 'appear nav-btn' : `nav-btn ${state}`} variant="ghost"><Link className="btn-link" to="/evenimente">{lang === 'RO' ? 'Evenimente' : 'Events'}</Link></Button>
                        }

                        <Button fontSize="2xl" className={y <= 80 ? 'appear nav-btn' : `nav-btn ${state}`} variant="ghost"><a className="btn-link" href="/#contact">Contact</a></Button>

                        {
                            title === "Rezervări" || title === 'Booking' ?
                                <Button fontSize="2xl" className={y <= 80 ? 'appear selected' : `selected ${state}`} variant="ghost"><Link className="btn-link" to="/rezervari">{lang === 'RO' ? 'Rezervari' : 'Booking'}</Link></Button> :
                                <Button fontSize="2xl" className={y <= 80 ? 'appear nav-btn' : `nav-btn ${state}`} variant="ghost"><Link className="btn-link" to="/rezervari">{lang === 'RO' ? 'Rezervari' : 'Booking'}</Link></Button>
                        }
                        {
                            !isAuth() ? (
                                title === "Înregistrare" || title === "Intră în cont" || title === "Cont" || title === "Signup" || title === "Login" || title === "Account" ? (
                                    <Box position="relative">
                                        <Button onClick={() => setAuthDropOpen(!authDropOpen)} fontSize="2xl" className={y <= 80 ? 'appear selected' : `selected ${state}`} variant="ghost"><Icon as={BsFillPersonFill} /></Button>
                                        <VStack position="absolute" className={authDropOpen ? 'appear' : 'disappear'} top={y <= 80 ? 10 : 14} right={15} bg="black.dark" p={2} borderRadius="0 0 10px 10px">
                                            <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/inregistrare">{lang === 'RO' ? 'Înregistrare' : 'Singup'}</Link></Button>
                                            <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/logare">{lang === 'RO' ? 'Intră în cont' : 'Login'}</Link></Button>
                                        </VStack>
                                    </Box>
                                ) : (
                                    <Box position="relative">
                                        <Button onClick={() => setAuthDropOpen(!authDropOpen)} fontSize="2xl" className={y <= 80 ? 'appear nav-btn' : `nav-btn ${state}`} variant="ghost"><Icon as={BsFillPersonFill} /></Button>
                                        <VStack position="absolute" className={authDropOpen ? 'appear' : 'disappear'} top={y <= 80 ? 10 : 14} right={15} bg="black.dark" p={2} borderRadius="0 0 10px 10px">
                                            <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/inregistrare">{lang === 'RO' ? 'Înregistrare' : 'Signup'}</Link></Button>
                                            <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/logare">{lang === 'RO' ? 'Intră în cont' : 'Login'}</Link></Button>
                                        </VStack>
                                    </Box>
                                )

                            ) : (
                                title === "Înregistrare" || title === "Intră în cont" || title === "Cont" || title === "Signup" || title === "Login" || title === "Account" ? (

                                    <Box position="relative">
                                        <Button onClick={() => setAuthDropOpen(!authDropOpen)} fontSize="2xl" className={y <= 80 ? 'appear selected' : `selected ${state}`} variant="ghost"><Icon as={BsFillPersonFill} /></Button>
                                        <VStack position="absolute" className={authDropOpen ? 'appear' : 'disappear'} top={y <= 80 ? 10 : 14} right={15} bg="black.dark" p={2} borderRadius="0 0 10px 10px">
                                            <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/cont">{lang === 'RO' ? 'Cont' : 'Account'}</Link></Button>
                                            <Button className="nav-btn" fontSize="xl" variant="ghost" onClick={logout}>{lang === 'RO' ? 'Deconectare' : 'Logout'}</Button>
                                        </VStack>
                                    </Box>
                                ) : (
                                    <Box position="relative">
                                        <Button onClick={() => setAuthDropOpen(!authDropOpen)} fontSize="2xl" className={y <= 80 ? 'appear nav-btn' : `nav-btn ${state}`} variant="ghost"><Icon as={BsFillPersonFill} /></Button>
                                        <VStack position="absolute" className={authDropOpen ? 'appear' : 'disappear'} top={y <= 80 ? 10 : 14} right={15} bg="black.dark" p={2} borderRadius="0 0 10px 10px">
                                            <Button className="nav-btn" fontSize="xl" variant="ghost"><Link className="btn-link" to="/cont">{lang === 'RO' ? 'Cont' : 'Account'}</Link></Button>
                                            <Button className="nav-btn" fontSize="xl" variant="ghost" onClick={logout}>{lang === 'RO' ? 'Deconectare' : 'Logout'}</Button>
                                        </VStack>
                                    </Box>
                                )
                            )
                        }
                        <Button onClick={handleLang} fontSize="2xl" className={y <= 80 ? 'appear nav-btn' : `nav-btn ${state}`} variant="ghost">
                            <Flag code={lang === 'RO' ? 'RO' : 'GB'} height="30px" width="30px" />
                        </Button>
                    </HStack>
                </HStack>
            </HStack>

            <HStack position="fixed" zIndex={99} display={innerWidth < 1070 ? 'flex' : 'none'} justifyContent="flex-start" className='nav-bar' bg="black.dark" color="white.cultured" p={4}>
                <Button ref={btnRef} variant="ghost" onClick={onOpen}>
                    <HamburgerIcon fontSize="xl" />
                </Button>
                <Box ml="auto" mr="auto">
                    <Image onClick={() => window.location.href = '/'} src="/images/logo-alb.svg" width="25vh" height="12vh" />
                </Box>
                <Drawer isOpen={isOpen} placement='left' onClose={onClose} finalFocusRef={btnRef} size="xs">
                    <DrawerOverlay display="block" />
                    <DrawerContent bg="black.dark" color="white.cultured" display="block" borderRadius="0 5px 5px 0" borderRight="solid 3px" borderRightColor="accents.manhattan">
                        <DrawerCloseButton fontSize="xl" pt={4} pr={2} />
                        <DrawerHeader>
                            <Button onClick={handleLang} fontSize="xl" className='nav-btn' variant="ghost">
                                <Flag code={lang === 'RO' ? 'RO' : 'GB'} height="30px" width="30px" />
                            </Button>
                        </DrawerHeader>
                        <DrawerBody pt={16} overflow="hidden">
                            <VStack alignItems="flex-start">
                                <motion.div animate={isOpen ? 'open' : 'close'} variants={homeAnim}>
                                    {
                                        title === "Grand Hotel Ceahlău" ?
                                            <Button fontSize="xl" variant="ghost" className="selected"><Link className="btn-link" to="/"><Icon as={ImHome} mr={1} fontSize="lg" />{lang === 'RO' ? 'Acasă' : 'Home'}</Link></Button> :
                                            <Button fontSize="xl" variant="ghost" className="nav-btn"><Link className="btn-link" to="/"><Icon as={ImHome} mr={1} fontSize="lg" />{lang === 'RO' ? 'Acasă' : 'Home'}</Link></Button>
                                    }
                                </motion.div>
                                <motion.div animate={isOpen ? 'open' : 'close'} variants={camereAnim}>
                                    {
                                        title === "Camere" || title === "Rooms" || title === "Single" || title === "Double" || title === "King Size" || title === "Apartment" ?
                                            <Button onClick={() => setDropOpen(!dropOpen)} className="selected" fontSize="xl" variant="ghost"><Icon as={FaBed} mr={1} fontSize="lg" />{lang === 'RO' ? 'Camere' : 'Rooms'}</Button> :
                                            <Button onClick={() => setDropOpen(!dropOpen)} className="nav-btn" fontSize="xl" variant="ghost"><Icon as={FaBed} mr={1} fontSize="lg" />{lang === 'RO' ? 'Camere' : 'Rooms'}</Button>
                                    }
                                </motion.div>
                                <VStack pl={6} alignItems="flex-start" className={dropOpen ? 'appear' : 'disappear'} display={dropOpen ? 'flex' : 'none'}>
                                    <Button className="nav-btn" fontSize="lg" id="btn-group" variant="ghost"><Link className="btn-link" to="/camere">{lang === 'RO' ? 'Toate Camerele' : 'All Rooms'}</Link></Button>
                                    <Button className="nav-btn" fontSize="lg" id="btn-group" variant="ghost"><Link className="btn-link" to="/camere/single">Single</Link></Button>
                                    <Button className="nav-btn" fontSize="lg" id="btn-group" variant="ghost"><Link className="btn-link" to="/camere/double">Double</Link></Button>
                                    <Button className="nav-btn" fontSize="lg" id="btn-group" variant="ghost"><Link className="btn-link" to="/camere/king-size">King Size</Link></Button>
                                    <Button className="nav-btn" fontSize="lg" id="btn-group" variant="ghost"><Link className="btn-link" to="/camere/apartment">Apartment</Link></Button>
                                </VStack>
                                <motion.div animate={isOpen ? 'open' : 'close'} variants={evenimenteAnim}>
                                    {
                                        title === "Evenimente" || title === 'Events' ?
                                            <Button fontSize="xl" variant="ghost" className="selected"><Link className="btn-link" to="/evenimente"><Icon as={FaGlassCheers} mr={1} fontSize="lg" />{lang === 'RO' ? 'Evenimente' : 'Events'}</Link></Button> :
                                            <Button id="btn-group" fontSize="xl" variant="ghost" className="nav-btn"><Link className="btn-link" to="/evenimente"><Icon as={FaGlassCheers} mr={1} fontSize="lg" />{lang === 'RO' ? 'Evenimente' : 'Events'}</Link></Button>
                                    }
                                </motion.div>
                                <motion.div animate={isOpen ? 'open' : 'close'} variants={contactAnim}>
                                    <Button id="btn-group" fontSize="xl" className="nav-btn" variant="ghost"><a className="btn-link" href="/#contact"><Icon as={HiPhone} mr={1} fontSize="lg" />Contact</a></Button>
                                </motion.div>
                                <motion.div animate={isOpen ? 'open' : 'close'} variants={bookAnim}>
                                    {
                                        title === "Rezervări" || title === 'Booking' ?
                                            <Button fontSize="xl" variant="ghost" className="selected"><Link className="btn-link" to="/rezervari"><Icon as={RiBookmark3Fill} mr={1} fontSize="lg" />{lang === 'RO' ? 'Rezervari' : 'Booking'}</Link></Button> :
                                            <Button id="btn-group" fontSize="xl" variant="ghost" className="nav-btn"><Link className="btn-link" to="/rezervari"><Icon as={RiBookmark3Fill} mr={1} fontSize="lg" />{lang === 'RO' ? 'Rezervari' : 'Booking'}</Link></Button>
                                    }
                                </motion.div>
                                <motion.div animate={isOpen ? 'open' : 'close'} variants={accountAnim}>
                                    {
                                        title === "Înregistrare" || title === "Intră în cont" || title === "Cont" || title === "Signup" || title === "Login" || title === "Account" ?
                                            <Button onClick={() => setAuthDropOpen(!authDropOpen)} className="selected" fontSize="xl" variant="ghost"><Icon mr={1} fontSize="lg" as={BsFillPersonFill} />{lang === 'RO' ? 'Cont' : 'Account'}</Button> :
                                            <Button onClick={() => setAuthDropOpen(!authDropOpen)} className="nav-btn" fontSize="xl" variant="ghost"><Icon mr={1} fontSize="lg" as={BsFillPersonFill} />{lang === 'RO' ? 'Cont' : 'Account'}</Button>
                                    }
                                </motion.div>
                                {
                                    !isAuth() ? (
                                        <VStack pl={6} alignItems="flex-start" className={authDropOpen ? 'appear' : 'disappear'} display={authDropOpen ? 'flex' : 'none'}>
                                            <Button className="nav-btn" fontSize="lg" variant="ghost"><Link className="btn-link" to="/inregistrare">{lang === 'RO' ? 'Înregistrare' : 'Signup'}</Link></Button>
                                            <Button className="nav-btn" fontSize="lg" variant="ghost"><Link className="btn-link" to="/logare">{lang === 'RO' ? 'Intră în cont' : 'Login'}</Link></Button>
                                        </VStack>
                                    ) : (
                                        <VStack pl={6} alignItems="flex-start" className={authDropOpen ? 'appear' : 'disappear'} display={authDropOpen ? 'flex' : 'none'}>
                                            <Button className="nav-btn" fontSize="lg" variant="ghost"><Link className="btn-link" to="/cont">{lang === 'RO' ? 'Cont' : 'Account'}</Link></Button>
                                            <Button className="nav-btn" fontSize="lg" variant="ghost" onClick={logout}>{lang === 'RO' ? 'Deconectare' : 'Logout'}</Button>
                                        </VStack>
                                    )
                                }
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </HStack>
        </Box>
    )
}
export default function Nav({ title, children }) {
    return (
        <Box minH="50px">
            <VStack align="stretch" justifyContent="space-between" spacing={8}>
                <Navbar title={title} />
                <Box position='relative' zIndex='0' as="main" h="full" >
                    {children}
                </Box>
            </VStack>
        </Box>
    )
}