import { useState } from "react";
import { axiosInstance } from "../config";
import { setCookie } from "../utils/authHelpers";

const useFetch = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleGoogle = async (response) => {
        setLoading(true)
        const data = (await axiosInstance.post('/auth/googleLogin', { tokenId: response.credential })).data
        setCookie('token', data.token)
        setLoading(false)
        window.location.href = '/cont'
    };
    return { loading, error, handleGoogle };
};

export default useFetch;