import { Stack, Button, Heading, Icon, StackDivider, HStack, VStack, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { FaFacebook, FaTripadvisor, FaRegCopyright, FaMapMarkedAlt } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { MdLocalPhone, MdLocationCity } from "react-icons/md"
import { HiMail } from "react-icons/hi"
import { Link } from 'react-router-dom'

export default function Footer() {
  const [innerWidth, setInnerWidth] = useState(getWidth())
  const [lang, setLang] = useState(window.localStorage.lang)

  const handleLocalStorage = useCallback((e) => {
    const window = e.currentTarget;
    setLang(window.localStorage.lang);
  }, [lang])

  useEffect(() => {
    setLang(window.localStorage.lang);
    window.addEventListener("click", handleLocalStorage);
    return () => {
      window.removeEventListener("click", handleLocalStorage);
    };
  }, [handleLocalStorage]);

  function getWidth() {
    const { innerWidth: width } = window
    return width
  }

  useEffect(() => {
    function handleResize() {
      setInnerWidth(getWidth())
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const year = (new Date()).getFullYear()
  function handleClick(link) {
    window.open(`${link}`)
  }
  return (
    <Stack alignContent="stretch" color="white.cultured" bg="black.dark" p={6} fontSize={['md', , 'lg']}>
      <Stack direction='row' justifyContent="center" gap={3} divider={<StackDivider borderColor="accents.manhattan" display={innerWidth >= 500 ? 'block' : 'none'} />}>
        <VStack alignItems="flex-start">
          <HStack>
            <Icon fontSize="2vh" as={MdLocalPhone} />
            <Text>{/*0233 219 990*/}0233 219 090</Text>
          </HStack>
          <HStack>
            <Icon fontSize="2vh" as={HiMail} />
            <Text>office@hotelceahlau.ro</Text>
          </HStack>
          <Text display={innerWidth < 500 ? 'block' : 'none'} ><Icon fontSize="2vh" mr={1} as={FaMapMarkedAlt} />{lang === 'RO' ? 'Piața Ştefan cel Mare 3, Piatra Neamț' : 'Ştefan The Great Square 3, Piatra Neamț'}</Text>
        </VStack>
        <VStack alignItems="flex-end" display={innerWidth >= 500 ? 'flex' : 'none'}>
          <HStack>
            <Text>Piatra Neamț</Text>
            <Icon fontSize="2vh" ml={1} as={MdLocationCity} />
          </HStack>
          <HStack>
            <Text>{lang === 'RO' ? 'Piața Ştefan cel Mare 3' : 'Ştefan The Great Square 3'}</Text>
            <Icon fontSize="2vh" ml={1} as={FaMapMarkedAlt} />
          </HStack>
        </VStack>
      </Stack>
      <Stack direction={innerWidth < 950 ? 'column-reverse' : 'row'} justifyContent="center" divider={<StackDivider borderColor="accents.manhattan" />}>
        <HStack justifyContent='center'>
          <Heading fontSize={['md', , 'lg']}>Grand Hotel Ceahlău</Heading>
          <Icon as={FaRegCopyright} fontSize="2vh" />
          <Heading fontSize={['md', , 'lg']}>{year}</Heading>
        </HStack>
        <Stack width="fit-content" direction={innerWidth < 950 ? 'column' : 'row'} justifyContent="center" alignItems='center' ml={innerWidth < 950 ? 'auto' : 0} mr={innerWidth < 950 ? 'auto' : 0}>
          {/*<Button fontSize={['md', , 'lg']} variant="ghost" className='nav-btn' ><Link to="/cariere">{lang === 'RO' ? 'Cariere' : 'Jobs'}</Link></Button>*/}
          <Button fontSize={['md', , 'lg']} variant="ghost" className='nav-btn' ><Link to="/termeni-si-conditii">{lang === 'RO' ? 'Termeni și condiții' : 'Terms and conditions'}</Link></Button>
          <Button fontSize={['md', , 'lg']} variant="ghost" className='nav-btn' ><Link to="/protectia-datelor">{lang === 'RO' ? 'Protecția datelor' : 'Personal Data Protection'}</Link></Button>
          <Button fontSize={['md', , 'lg']} onClick={() => handleClick('https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage')} variant="ghost" className='nav-btn' >{lang === 'RO' ? 'Soluționarea litigiilor' : 'Online Dispute Resolution'}</Button>
          <Button fontSize={['md', , 'lg']} onClick={() => handleClick('https://anpc.ro/')} variant="ghost" className='nav-btn' >ANPC</Button>
        </Stack>
        <HStack justifyContent="center">
          <IconContext.Provider value={{ color: "white.cultured", size: '1.5em' }}>
            <Button onClick={() => handleClick('https://www.facebook.com/GrandHotelCeahlauPiatraNeamt/?ref=page_internal')} className="nav-btn" variant="ghost"><FaFacebook color='#E4AD77' /></Button>
            <Button onClick={() => handleClick('https://www.tripadvisor.com/Hotel_Review-g304062-d498179-Reviews-Grand_Hotel_Ceahlau-Piatra_Neamt_Neamt_County_Northeast_Romania.html')} className="nav-btn" variant="ghost"><FaTripadvisor color='#E4AD77' /></Button>
          </IconContext.Provider>
        </HStack>
      </Stack>
    </Stack>
  )
}