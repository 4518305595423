import { Box, Image, VStack } from '@chakra-ui/react';
import Lottie from 'react-lottie';
import animationData from '../utils/loader.json'
import CountUp from 'react-countup';
import React from "react"
export default class LottieControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isStopped: false, isPaused: false, innerWidth: '', loading: true };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ innerWidth: window.innerWidth })
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        let maximum
        if (this.state.innerWidth <= 280) maximum = 200
        if (this.state.innerWidth > 280 && this.state.innerWidth <= 425) maximum = 250
        if (this.state.innerWidth > 425 && this.state.innerWidth <= 912) maximum = 400
        if (this.state.innerWidth > 912) maximum = 500

        setInterval(() => {
            this.setState({ loading: false })
        }, 3000)

        return (
            <VStack bgGradient="linear(128deg, #F4DEC9, #D4DBE5)" fontSize={['4xl', '7xl']} height="110vh" width={["110%", , "100%"]} position="absolute" zIndex={2} display={this.state.loading ? 'block' : 'none'}>
                <Image ml="auto" mr="auto" src='/images/logo.svg' width="50vh" height="30vh" />
                <Lottie options={defaultOptions}
                    height={maximum}
                    width={maximum}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused} />
                <Box textAlign="center">
                    <CountUp end={100} duration={3} suffix="%" />
                </Box>
            </VStack>
        )
    }
}