import { Box, Button, Heading, Icon, Input, InputGroup, InputLeftElement, Text, VStack } from "@chakra-ui/react"
import Layout from "../components/Layout"
import { BsFillPersonFill } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import { useCallback, useEffect, useState } from "react"
import Loading from "../components/Loading"
import { axiosInstance } from "../config"
import { Link } from "react-router-dom"
import Google from "../components/Google"
import { isAuth } from "../utils/authHelpers"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export default function Inregistrare({ innerWidth }) {
    const [loading, setLoading] = useState(false)
    const [account, setAccount] = useState({
        name: '',
        email: '',
    })
    const [lang, setLang] = useState(window.localStorage.lang)
    const [btnPressed, setBtnPressed] = useState(false)

    useEffect(() => {
        if (isAuth()) window.location.href = '/'
    }, [])

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            await axiosInstance.post("/auth/signup", { name: account.name, email: account.email, lang })
                .then(res => {
                    setLoading(false)
                    //setBtnPressed(true)
                    console.log(res)
                    toast.success(res.data.message[lang.toLowerCase()])
                })
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.error[lang.toLowerCase()])
            setLoading(false)
        }
    }

    return (
        <Layout title={lang === 'RO' ? "Înregistrare" : 'Signup'}>
            <ToastContainer pauseOnHover theme="colored" style={{
                marginTop: '150px'
            }} />
            {
                loading ? (
                    <Loading />
                ) : (
                    <VStack mt={40} width="fit-content" ml="auto" mr="auto" p={16} borderRadius={8} className="registration">
                        <Heading fontSize={['5xl', , '6xl']} mb={2}>{lang === 'RO' ? 'Înregistrare' : 'Signup'}</Heading>
                        <Box pb={8}>
                            <form>
                                <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Nume si prenume' : 'Full Name'}:</Text>
                                <InputGroup>
                                    <InputLeftElement children={<Icon color="black.dark" fontSize='3vh' mt={2} as={BsFillPersonFill} />} />
                                    <Input fontSize={['lg', , '2xl']} value={account['name']} onChange={(e) => setAccount({ ...account, ['name']: e.target.value })} type="text" name="name" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                                </InputGroup>

                                <Text fontSize={['lg', , '2xl']} pt={4}>Email:</Text>
                                <InputGroup>
                                    <InputLeftElement children={<Icon color="black.dark" fontSize='3vh' mt={2} as={MdEmail} />} />
                                    <Input fontSize={['lg', , '2xl']} value={account['email']} onChange={(e) => setAccount({ ...account, ['email']: e.target.value })} type="email" name="email" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                                </InputGroup>
                            </form>
                        </Box>
                        <Button isDisabled={btnPressed} fontSize={['lg', , '2xl']} onClick={handleSubmit} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Înregistrare' : 'Signup'}</Button>
                        <Link to="/logare"><Text fontSize={['md', , 'xl']}>{lang === 'RO' ? 'Ai deja cont? Conectează-te' : 'Already have an account? Login'}</Text></Link>
                        <Text fontSize={['md', , 'xl']} className="textLines">{lang === 'RO' ? 'Sau' : 'Or'}</Text>
                        <Google />
                    </VStack>
                )
            }
        </Layout>
    )
}