import Lottie from 'react-lottie';
import animationData from '../utils/404.json'
import React from "react"
import { Button, VStack } from '@chakra-ui/react';

export default class LottieControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isStopped: false, isPaused: false, innerWidth: window.innerWidth, lang: window.localStorage.lang };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()

        window.addEventListener('click', this.handleLocalStorage.bind(this))
        this.handleLocalStorage()
    }Acasă

    resize() {
        this.setState({ innerWidth: window.innerWidth })
    }

    handleLocalStorage(){
        this.setState({lang: window.localStorage.lang})
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        let maximum
        if (this.state.innerWidth <= 280) maximum = 200
        if (this.state.innerWidth > 280 && this.state.innerWidth < 500) maximum = 300
        if (this.state.innerWidth >= 500 && this.state.innerWidth <= 912) maximum = 500
        if (this.state.innerWidth > 912) maximum = 600

        return (
            <VStack mt={62} mb={8}>
                <Lottie options={defaultOptions}
                    height={maximum}
                    width={maximum}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused} />
                <Button className='btn-group' fontSize={['3xl', , '5xl']} p={6} onClick={() => window.location.href = "/"} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{this.state.lang==='RO' ? 'Acasă' : 'Home'}</Button>
            </VStack>
        )
    }
}