import { Box} from '@chakra-ui/react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import RoomCard from './RoomCard';

export default function Carousel({ elements, roomTypes, lang }) {
    return (
        <Box height="100%">
            <Slide slidesToScroll={1} slidesToShow={1.15} indicators={true} autoplay={false} arrows={false}>
                {
                    elements.map((e, index) => (
                        <RoomCard key={index} type={roomTypes[index].type} area={roomTypes[index].area} img={e} maxCount={roomTypes[index].maxCount} desc={roomTypes[index].desc[lang.toLowerCase()]} price={roomTypes[index].price} lang={lang} />
                    ))
                }
            </Slide>
        </Box>
    )
}