import { Box, Heading } from "@chakra-ui/react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { useEffect, useState } from "react"
import { axiosInstance } from "../config"
import CheckoutForm from "./CheckoutForm"

export default function Checkout({ amount, bookingId, getPaymentId, lang }) {
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState("")

    useEffect(() => {
        async function getData() {
            const { publishableKey } = (await axiosInstance.get("/payment/config")).data
            setStripePromise(loadStripe(publishableKey))
        }
        getData()
    }, [])

    useEffect(() => {
        async function getData() {
            const { clientSecret, paymentIntentId } = (await axiosInstance.post("/payment/create-payment-intent", { amount })).data
            setClientSecret(clientSecret)
            getPaymentId(paymentIntentId)
        }
        getData()
    }, [])
    return (
        <Box>
            {
                stripePromise && clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <Heading fontSize={['3xl', , '4xl']} pb={4}>Payment of {amount} LEI</Heading>
                        <CheckoutForm bookingId={bookingId} amount={amount} lang={lang} />
                    </Elements>
                )
            }
        </Box>
    )
}