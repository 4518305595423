import Lottie from 'react-lottie';
import animationData from '../utils/loading.json'
import React from "react"
import { Box } from '@chakra-ui/react';

export default class LottieControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isStopped: false, isPaused: false, innerWidth: '' };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ innerWidth: window.innerWidth })
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        let maximum
        if (this.state.innerWidth <= 280) maximum = 175
        if (this.state.innerWidth > 280 && this.state.innerWidth <= 425) maximum = 300
        if (this.state.innerWidth > 425 && this.state.innerWidth <= 912) maximum = 400
        if (this.state.innerWidth > 912) maximum = 500

        return (
            <Box mt={56}>
                <Lottie options={defaultOptions}
                    height={maximum}
                    width={maximum}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused} />
            </Box>
        )
    }
}