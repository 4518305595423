import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Heading, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Text, VStack } from "@chakra-ui/react";
import { RiLockPasswordFill } from "react-icons/ri"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import Loading from "../components/Loading"
import { axiosInstance } from "../config";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export default function ResetPassword() {
    const { token } = useParams()
    useEffect(() => {
        setAccount({ ...account, token })
    }, [])

    const [account, setAccount] = useState({
        token: "",
        password: "",
        passwordVerif: ""
    })
    const [activated, setActivated] = useState(false)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState({
        password: false,
        passwordVerif: false
    })

    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const handlePasswordVerifVisible = () => {
        if (visible['passwordVerif']) setVisible({ ...visible, ['passwordVerif']: false })
        else setVisible({ ...visible, ['passwordVerif']: true })
    }

    const handlePasswordVisible = () => {
        if (visible['password']) setVisible({ ...visible, ['password']: false })
        else setVisible({ ...visible, ['password']: true })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            await axiosInstance.post("/users/resetPassword", { resetPasswordLink: account.token, newPassword: account.password, passwordVerif: account.passwordVerif })
            setActivated(true)
            setLoading(false)
        } catch (err) {
            toast.error(err.response.data.error[lang.toLowerCase()])
            setLoading(false)
        }
    }

    return (
        <Layout title={lang==='RO' ? "Resetare Parolă" : 'Reset Password'}>
            <ToastContainer pauseOnHover theme="colored" style={{
                marginTop: '150px'
            }} />
            {
                loading ? (
                    <Loading />
                ) : (
                    !activated ? (
                        <VStack mt={40} width="fit-content" ml="auto" mr="auto" p={16} borderRadius={8} className="registration">
                            <Heading fontSize={['5xl', , '6xl']} mb={2} textAlign='center'>{lang==='RO' ? 'Resetarea Parolei' : 'Reset Password'}</Heading>
                            <Box pb={8}>
                                <form>
                                    <Text fontSize={['lg', , '2xl']} pt={4}>{lang==='RO' ? 'Parola' : 'Password'}:</Text>
                                    <InputGroup>
                                        <InputLeftElement children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={RiLockPasswordFill} />} />
                                        <Input fontSize={['lg', , '2xl']} autoComplete="off" value={account['password']} onChange={(e) => setAccount({ ...account, ['password']: e.target.value })} type={visible['password'] ? 'text' : 'password'} name="password" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                                        <InputRightElement cursor="pointer" onClick={handlePasswordVisible} children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={visible['password'] ? AiFillEye : AiFillEyeInvisible} />} />
                                    </InputGroup>

                                    <Text fontSize={['lg', , '2xl']} pt={4}>{lang==='RO' ? 'Confirmă parola' : 'Confirm password'}:</Text>
                                    <InputGroup>
                                        <InputLeftElement children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={RiLockPasswordFill} />} />
                                        <Input fontSize={['lg', , '2xl']} autoComplete="off" value={account['passwordVerif']} onChange={(e) => setAccount({ ...account, ['passwordVerif']: e.target.value })} type={visible['passwordVerif'] ? 'text' : 'password'} name="passwordVerif" required fontWeight="bold" size='lg' focusBorderColor="accents.manhattan" />
                                        <InputRightElement cursor="pointer" onClick={handlePasswordVerifVisible} children={<Icon mt={2} fontSize='2.5vh' color="black.dark" as={visible['passwordVerif'] ? AiFillEye : AiFillEyeInvisible} />} />
                                    </InputGroup>
                                </form>
                            </Box>
                            <Button fontSize={['lg', , '2xl']} onClick={handleSubmit} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang==='RO' ? 'Resetare' : 'Reset'}</Button>
                        </VStack>
                    ) : (
                        <VStack pt={130} textAlign="center">
                            <Heading fontSize={['5xl', , '6xl']}>{lang==='RO' ? 'Parola a fost resetată' : 'Password has been reseted'}!</Heading>
                            <Text fontSize={['lg', , '2xl']}>{lang==='RO' ? 'Vă puteți conecta cu noua parolă' : 'You can login with the new password'} <Link to="/logare" style={{ color: "#D5873F" }}>{lang==='RO' ? 'aici' : 'here'}</Link></Text>
                        </VStack>
                    )
                )

            }
        </Layout>
    )
}