import { Box, Button, Heading, Image, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import Layout from "../components/Layout";
import SlideShow from "../components/SlideShow";
import { BsArrowsAngleExpand, BsEggFried } from "react-icons/bs"
import { MdPeople } from "react-icons/md"
import { IoIosPricetags } from "react-icons/io"
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Room({ innerWidth, title, desc, maxCount, price, area, img }) {

    const [lang, setLang] = useState(window.localStorage.lang)

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    const [y, setY] = useState(window.scrollY)
    const handleNavigation = useCallback((e) => {
        const window = e.currentTarget;
        setY(window.scrollY);
    }, [y])

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    const Props = {
        duration: 3000,
        transitionDuration: 1000,
        infinite: true,
        indicators: true,
        scale: 0.4,
        arrows: true,
        pauseOnHover: false,
    }

    const hAnim = {
        offScreen: {
            y: 100,
            x: 100,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    }

    const textAnim = {
        offScreen: {
            y: 50,
            x: 50,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 1.2
            }
        }
    }

    const listAnim = {
        offScreen: {
            y: 100,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 1.6
            }
        }
    }

    const buttonAnim = {
        offScreen: {
            y: 50,
            opacity: 0
        },
        onScreen: {
            y: 0,
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    }

    return (
        <Layout title={title}>
            <Box position='fixed' zIndex={-2} left={0} top={innerWidth < 950 ? '12vh' : 0} width="100%" height="85vh">
                <Image transform={y > 80 ? "scale(1.15)" : ''} transitionDuration="0.5s" loading="eager" src={img[0]} width="100%" height={innerWidth < 950 ? '55vh' : '85vh'} />
            </Box>
            <Box mt={innerWidth < 950 ? '62vh' : '75vh'} p={16} bgColor="white.cultured" bgImage="url('/images/bg.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
                <motion.div initial={hAnim.offScreen} whileInView={hAnim.onScreen} viewport={{ once: true }}>
                    <Heading fontSize={['5xl', , '6xl']}>{lang == 'RO' ? 'Cameră ' : ''}{title}{lang != 'RO' ? ' Room' : ''}</Heading>
                </motion.div>
            </Box>
            <Box width="100%" height={innerWidth < 950 ? '55vh' : '85vh'}>
                <SlideShow innerWidth={innerWidth} images={img} width="100%" height={innerWidth < 950 ? '55vh' : '85vh'} SlideShowProps={Props} />
            </Box>
            <Box p={16} bgColor="white.cultured" fontSize={['xl', , '3xl']} bgImage="url('/images/bg.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
                <motion.div initial={listAnim.offScreen} whileInView={listAnim.onScreen} viewport={{ once: true }}>
                    <List spacing={3} borderLeftStyle="solid" borderLeftWidth={2} borderLeftColor="accents.raw-sienna" pl={4}>
                        <ListItem>
                            <ListIcon as={BsEggFried} />
                            {lang === 'RO' ? 'Mic de jun inclus' : 'Breakfast included'}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={BsArrowsAngleExpand} />
                            {area} m<sup>2</sup>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdPeople} />
                            {lang === 'RO' ? `maxim ${maxCount} ${maxCount === 1 ? 'persoană' : 'persoane'}` : `maximum ${maxCount} ${maxCount === 1 ? 'person' : 'people'}`}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={IoIosPricetags} />
                            {price} RON /<sub>{lang === 'RO' ? 'noapte' : 'night'}</sub>
                        </ListItem>
                        <ListItem>
                            {desc[lang.toLowerCase()]}
                        </ListItem>
                    </List>
                </motion.div>
                <motion.div initial={buttonAnim.offScreen} whileInView={buttonAnim.onScreen} viewport={{ once: true }}>
                    <Button fontSize={['xl', , '3xl']} p={5} mt={4} className='btn-group' bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}><Link to='/rezervari'>{lang === 'RO' ? 'Rezervă Acum' : 'Book Now'}</Link></Button>
                </motion.div>
            </Box>
        </Layout >
    )
}