import { Box, Icon, Image} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons"
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useState } from "react";
import {motion} from "framer-motion"

function SlideShow(props) {
  const [hovered, setHovered]=useState(false)
  useEffect(()=>{
    if(props.innerWidth<1024) setHovered(true)
  }, [props.innerWidth])
  const arrowAnim={
    open:{
      x:[-10, 0],
      opacity:1
    },
    close:{
      opacity:0
    }
  }
  return (
    <Box className="slide-container" width={props.width} height={props.height} position="relative" onMouseOver={()=>setHovered(true)} onMouseOut={()=>props.innerWidth>=1024 ? setHovered(false) : setHovered(true)}>
      <Slide {...props.SlideShowProps} nextArrow={<motion.div animate={hovered ? "open" : "close"} variants={arrowAnim}><Icon as={ChevronRightIcon} fontSize="8vh" color="accents.raw-sienna" /></motion.div>} prevArrow={<motion.div animate={hovered ? "open" : "close"} variants={arrowAnim}><Icon as={ChevronLeftIcon} fontSize="8vh" color="accents.raw-sienna" /></motion.div>}>
        {(props.images).map((image, index) => (
          <Box className="each-fade" key={index}>
            <Box className="image-container">
              <Image src={image} alt={index} width="100%" height={props.height} loading="eager" />
            </Box>
          </Box>
        ))}
      </Slide>
    </Box>
  )
}

export default SlideShow;