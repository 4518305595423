import { Box, Button, Grid, Heading, HStack, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, StackDivider, Text, useDisclosure, VStack } from "@chakra-ui/react"
import Layout from "../components/Layout"
import { useCallback, useEffect, useState } from "react"
import Loading from "../components/Loading"
import { axiosInstance } from "../config"
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons"
import { getCookie, isAuth } from "../utils/authHelpers"
import Sidebar from "../components/Sidebar"
import Checkout from "../components/Checkout"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import compareAsc from "date-fns/compareAsc"
import { differenceInDays } from "date-fns"

export default function Rezervation({ innerWidth }) {
    const [loading, setLoading] = useState(true)
    const [bookings, setBookings] = useState()
    const [roomSelection, setRoomSelection] = useState([])
    const [direction, setDirection] = useState('normal')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: cancelIsOpen, onOpen: cancelOnOpen, onClose: cancelOnClose } = useDisclosure()
    const { isOpen: refundIsOpen, onOpen: refundOnOpen, onClose: refundOnClose } = useDisclosure()
    const [amount, setAmount] = useState(0)
    const [bookingId, setBookingId] = useState('')
    const [lang, setLang] = useState(window.localStorage.lang)
    const [paymentIntentId, setPaymentIntentId] = useState('')

    const handleLocalStorage = useCallback((e) => {
        const window = e.currentTarget;
        setLang(window.localStorage.lang);
    }, [lang])

    useEffect(() => {
        setLang(window.localStorage.lang);
        window.addEventListener("click", handleLocalStorage);
        return () => {
            window.removeEventListener("click", handleLocalStorage);
        };
    }, [handleLocalStorage]);

    useEffect(() => {
        async function getUser() {
            if (window) {
                const token = getCookie('token')
                try {
                    setLoading(true)
                    const data = (await axiosInstance.post('/users/getUser', { token })).data
                    setBookings(data.user.bookings)
                    data.user.bookings.map(booking => {
                        const types = {
                            single: 0,
                            double: 0,
                            kingSize: 0,
                            apartment: 0,
                        }
                        booking.rooms.map(room => {
                            types[room.type]++
                        })
                        setRoomSelection(current => [...current, types])
                    })
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    window.location.href = '/'
                }
            }
        }
        if (!isAuth()) {
            window.location.href = '/logare'
        } else {
            getUser()
        }
    }, [])

    const getDate = (stringDate) => {
        const date = new Date(stringDate)
        return String(date.getDate()) + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    }

    const payErr = {
        ro: 'Termenul de plată a trecut! Vă rugăm să faceți altă rezervare',
        en: 'Past due date! Please make another booking'
    }

    const refundErr = {
        ro: 'Termenul de restituire a banilor a trecut!',
        en: 'Refund date has passed!'
    }

    const openModal = (amount, bookingId, dueDate) => {
        const dateNow = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        }
        const dateDue = {
            year: new Date(dueDate).getFullYear(),
            month: new Date(dueDate).getMonth() + 1,
            day: new Date(dueDate).getDate()
        }
        const diff = compareAsc(new Date(dateDue.year, dateDue.month, dateDue.day), new Date(dateNow.year, dateNow.month, dateNow.day))
        if (diff < 0) {
            toast.error(payErr[lang.toLowerCase()])
        } else {
            setAmount(amount)
            setBookingId(bookingId)
            onOpen()
        }
    }

    const refundModalOpen = (amount, id, checkIn) => {
        const dateNow = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        }
        const dateDue = {
            year: new Date(checkIn).getFullYear(),
            month: new Date(checkIn).getMonth() + 1,
            day: new Date(checkIn).getDate()
        }
        const diff = compareAsc(new Date(dateDue.year, dateDue.month, dateDue.day), new Date(dateNow.year, dateNow.month, dateNow.day))
        if (diff < 0) {
            toast.error(refundErr[lang.toLowerCase()])
        } else {
            const days = differenceInDays(new Date(dateDue.year, dateDue.month, dateDue.day), new Date(dateNow.year, dateNow.month, dateNow.day))
            if (days >= 5) setAmount(amount)
            else if (days >= 2) setAmount(amount * 0.5)
            else {
                toast.error(refundErr[lang.toLowerCase()])
                return;
            }
            setBookingId(id)
            refundOnOpen()
        }
    }

    const cancelModalOpen = (bookingId) => {
        setBookingId(bookingId)
        cancelOnOpen()
    }

    const handleSort = () => {
        if (direction === 'normal') {
            setDirection('reversed')
            setBookings(bookings.reverse())
            setRoomSelection(roomSelection.reverse())
        }
        else {
            setDirection('normal')
            setBookings(bookings.reverse())
            setRoomSelection(roomSelection.reverse())
        }
    }

    const errMessage = {
        ro: 'A apărut o eroare! Încercați mai târziu',
        en: 'Something went wrong! Try again later'
    }

    const handleCancel = async (bookingId) => {
        const successMessage = {
            ro: 'Rezervarea a fost anulată',
            en: 'Booking canceled successfully'
        }
        try {
            setLoading(true)
            await axiosInstance.post('/bookings/cancelBooking', { bookingId, lang })
            cancelOnClose()
            toast.success(successMessage[lang.toLowerCase()])
            setLoading(false)
            window.location.reload()
        } catch (err) {
            setLoading(false)
            toast.error(errMessage[lang.toLowerCase()])
        }
    }

    const handleRefund = async (bookingId) => {
        const successMessage = {
            ro: 'Cererea a fost înregistrată',
            en: 'Request registered successfully'
        }
        try {
            setLoading(true)
            await axiosInstance.post('/payment/refundBooking', { bookingId, amount, lang })
            setLoading(false)
            refundOnClose()
            toast.success(successMessage[lang.toLowerCase()])
            window.location.reload()
        } catch (err) {
            setLoading(false)
            toast.error(errMessage[lang.toLowerCase()])
        }
    }

    const getPaymentId = (id) => {
        setPaymentIntentId(id)
    }

    return (
        <Layout title={lang === 'RO' ? "Rezervările Tale" : 'Your Bookings'}>
            <ToastContainer pauseOnHover theme="colored" style={{
                marginTop: '150px'
            }} />
            {
                loading ? (
                    <Loading />
                ) : (
                    <Sidebar innerWidth={innerWidth}>
                        <VStack mt={40} mb={8}>
                            <Heading textAlign="center" pb={6} fontSize={['5xl', , '6xl']}>{lang === 'RO' ? 'Rezervările dvs.' : 'Your Bookings'}</Heading>
                            <HStack justifyContent="flex-end" width="100%" fontSize={innerWidth >= 1230 ? '2xl' : innerWidth >= 950 ? '2xl' : innerWidth >= 640 ? '2xl' : innerWidth >= 555 ? 'lg' : innerWidth >= 430 ? 'md' : innerWidth >= 385 ? 'xs' : 'xs'}>
                                <Text fontSize={['lg', , '2xl']} textAlign="center">{lang === 'RO' ? 'Sorteaza dupa data' : 'Sort by date'} :</Text>
                                <Button variant="ghost" onClick={handleSort}><Icon as={direction === 'normal' ? ArrowUpIcon : ArrowDownIcon} fontSize="3vh" /></Button>
                            </HStack>
                            <Grid gap={[6, , 20]} templateColumns={innerWidth < 1230 ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'}>
                                {
                                    bookings.map((booking, index) => (
                                        <VStack divider={<StackDivider borderColor='accents.raw-sienna' />} fontSize={innerWidth >= 1230 ? '2xl' : innerWidth >= 950 ? '2xl' : innerWidth >= 640 ? '2xl' : innerWidth >= 555 ? 'lg' : innerWidth >= 430 ? 'md' : innerWidth >= 385 ? 'xs' : 'xs'} key={index} className="registration" p={[4, , 16]} width={innerWidth >= 1230 ? '60vh' : innerWidth >= 950 ? '70vh' : innerWidth >= 640 ? '60vh' : innerWidth >= 555 ? '50vh' : innerWidth >= 430 ? '35vh' : innerWidth >= 385 ? '30vh' : innerWidth >= 300 ? '20vh' : 'fit-content'}>
                                            <Heading textAlign="center" fontSize={['3xl', , '4xl']}>{lang === 'RO' ? 'Rezervarea' : 'Booking'} {direction === 'normal' ? index + 1 : bookings.length - index}</Heading>
                                            <Box pt={[2, , 8]}>
                                                <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Nume' : 'Name'}: {booking.name}</Text>
                                                <Text fontSize={['lg', , '2xl']}>Email: {booking.email}</Text>
                                                <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Telefon' : 'Phone Number'}: {booking.phoneNr}</Text>
                                                <Text fontSize={['lg', , '2xl']}>Check-in: {getDate(booking.fromDate)}</Text>
                                                <Text fontSize={['lg', , '2xl']}>Check-out: {getDate(booking.toDate)}</Text>
                                                <Text fontSize={['lg', , '2xl']}>{booking.totalDays} {lang === 'RO' ? 'nopți' : 'nights'} - {booking.pers} {lang === 'RO' ? 'persoane' : 'people'}</Text>
                                                <Text fontSize={['lg', , '2xl']} display={[roomSelection[index].single ? 'block' : 'none']}>{lang === 'RO' ? 'Camere Single' : 'Single Rooms'}: {roomSelection[index].single}</Text>
                                                <Text fontSize={['lg', , '2xl']} display={[roomSelection[index].double ? 'block' : 'none']}>{lang === 'RO' ? 'Camere Double' : 'Double Rooms'}: {roomSelection[index].double}</Text>
                                                <Text fontSize={['lg', , '2xl']} display={[roomSelection[index].kingSize ? 'block' : 'none']}>{lang === 'RO' ? 'Camere King Size' : 'King Size Rooms'}: {roomSelection[index].kingSize}</Text>
                                                <Text fontSize={['lg', , '2xl']} display={[roomSelection[index].apartment ? 'block' : 'none']}>{lang === 'RO' ? 'Apartamente' : 'Apartments'}: {roomSelection[index].apartment}</Text>
                                                <Text fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Preț total' : 'Total price'}: {booking.totalAmount} LEI</Text>
                                                <Text fontSize={['lg', , '2xl']}>
                                                    {
                                                        lang === 'RO' ? 'Termen de plată: ' : 'Payment due: '
                                                    }
                                                    {getDate(booking.paymentBy)}
                                                </Text>
                                                <Text fontSize={['lg', , '2xl']}>
                                                    Status: {" "}
                                                    {
                                                        lang === 'RO' ? (
                                                            booking.status === 'pending' ? 'neplătită' :
                                                                booking.status === 'payed' ? 'plătită' :
                                                                    booking.status === 'refunded' ? 'banii au fost returnați' :
                                                                        'anulată'
                                                        ) : (
                                                            booking.status === 'pending' ? 'unpayed' :
                                                                booking.status === 'payed' ? 'payed' :
                                                                    booking.status === 'refunded' ? 'refunded' :
                                                                        'canceled'
                                                        )
                                                    }
                                                </Text>
                                                <Stack pt={4} direction={['column', , 'row']}>
                                                    <Button fontSize={['md', , 'xl']} display={booking.status === 'pending' ? 'flex' : 'none'} onClick={() => openModal(booking.totalAmount, booking._id, booking.paymentBy)} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Plătește' : 'Pay'}</Button>
                                                    <Button fontSize={['md', , 'xl']} display={booking.status === 'pending' ? 'flex' : 'none'} onClick={() => cancelModalOpen(booking._id)} p={4} bg="red.500" color="black.dark" borderColor="black.dark" borderWidth={2}>{lang === 'RO' ? 'Anulare' : 'Cancel'}</Button>
                                                </Stack>
                                                <Button pt={4} fontSize={['md', , 'xl']} display={booking.status === 'payed' ? 'flex' : 'none'} onClick={() => refundModalOpen(booking.totalAmount, booking._id, booking.fromDate)} p={4} className="btn-group" bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Restituirea banilor' : 'Refund'}</Button>
                                            </Box>
                                        </VStack>
                                    ))
                                }
                            </Grid>
                            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalCloseButton onClick={onClose} />
                                    <ModalBody m={6}>
                                        <Checkout amount={amount} bookingId={bookingId} getPaymentId={getPaymentId} lang={lang} />
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                            <Modal isOpen={cancelIsOpen} size='lg'>
                                <ModalOverlay />
                                <ModalContent bg="red.300">
                                    <ModalHeader textAlign="center" fontSize={['3xl', , '4xl']}>{lang === 'RO' ? 'Ești sigur că vrei să anulezi rezervarea' : 'Are you sure you want to cancel your booking'}?</ModalHeader>
                                    <ModalBody>
                                        <HStack justifyContent="center" spacing={12}>
                                            <Button fontSize={['lg', , '2xl']} bg="red" onClick={() => handleCancel(bookingId)}>{lang === 'RO' ? 'Anulare Rezervare' : 'Cancel Booking'}</Button>
                                            <Button fontSize={['lg', , '2xl']} onClick={cancelOnClose}>{lang === 'RO' ? 'Nu' : 'No'}</Button>
                                        </HStack>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                            <Modal isOpen={refundIsOpen} onClose={refundOnClose} size='lg'>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader textAlign='center' fontSize={['3xl', , '4xl']}>{lang === 'RO' ? 'Restituirea banilor' : 'Refund'}</ModalHeader>
                                    <ModalBody>
                                        <VStack>
                                            <Text textAlign='center' fontSize={['lg', , '2xl']}>{lang === 'RO' ? 'Suma de' : 'The'} {amount} RON {lang === 'RO' ? 'va fi restituită în contul dvs în 5-10 zile.' : 'will be refunded in your account in 5-10 days.'}</Text>
                                            <HStack justifyContent='center' spacing={12}>
                                                <Button fontSize={['lg', , '2xl']} onClick={() => handleRefund(bookingId)} className="btn-group" p={4} bg="black.jon" color="white.cultured" borderColor="accents.raw-sienna" borderWidth={2}>{lang === 'RO' ? 'Continuă' : 'Refund'}</Button>
                                                <Button fontSize={['lg', , '2xl']} borderStyle='solid' borderColor='black.dark' borderWidth={2} bg='accents.raw-sienna' color='white.cultured' onClick={refundOnClose}>{lang === 'RO' ? 'Anulare' : 'Cancel'}</Button>
                                            </HStack>
                                        </VStack>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </VStack>
                    </Sidebar>
                )
            }
        </Layout>
    )
}